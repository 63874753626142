import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Steps, Badge, Card, message } from "antd";
import Logo from "../../assets/SCO_asset/home/decathlon-logo.png";
import VerySatisfied from "../../assets/SCO_asset/feedback/green.png";
import Satisfied from "../../assets/SCO_asset/feedback/yellow.png";
import NotSatisfied from "../../assets/SCO_asset/feedback/red.png";
import Tick from "../../assets/SCO_asset/ebill-buttons/tick.svg";
import _ from "lodash";
import Axios from "axios";
import moment from "moment";
import URL from "../../app/server.json";
import db from "../../database";

const { Step } = Steps;
const FeedbackStepTwo = () => {
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    let tempName = localStorage.getItem("surname");
    setName(tempName);
  }, []);

  let cashier = JSON.parse(localStorage.getItem('cashierData'));
  let basicAuth = `${cashier.Username}:${cashier.Password}`

  const emojiFeedbackList = [
    {
      title: "Very Satisfied",
      img: VerySatisfied,
      no:3
    },
    {
      title: "Satisfied",
      img: Satisfied,
      no:2
    },
    {
      title: "Not Satisfied",
      img: NotSatisfied,
      no:1
    }
  ];

  const handleBillModeSelection = async(value, cardIndex,no) => {
    const status = navigator.onLine ? "online" : "offline";
    const selectedBillModeIndex = _.findIndex(emojiFeedbackList, ["title", value]);
    let emojiNumber
    if (selectedBillModeIndex === cardIndex) {
      emojiNumber=no;
      setSelectedValue(value);
    }
    let token = localStorage.getItem("token");
    let ebillMode = localStorage.getItem("billMode");
    let transactionId = localStorage.getItem("transactionId");
    let tempTransactionId = localStorage.getItem("tempTransactionId");
    let paymentMode = localStorage.getItem("paymentMode");
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    let orderDate = localStorage.getItem("orderDate");
    let salebegintime = localStorage.getItem("salebegintime");
    localStorage.setItem("saleendtime",moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
    let ismanual = localStorage.getItem("ismanual");
    let isphonepe = localStorage.getItem("isphonepe");
    let response = JSON.parse(localStorage.getItem('response'));
    let orderData = await db.syncData.toArray();
    let doorNo;
    let street;
    let zipcode;
    let city;
    let country;
    let state;
    let email;
    let name;
    let mobile;
    let total;
    let discount;
    let decathlonId;
    let cashierData = JSON.parse(localStorage.getItem('cashierData'));
    let orderId = localStorage.getItem("order_lastassignednum");
    let terminalId = localStorage.getItem("terminalNumber");
    let linesArray = []
    selectedProducts = selectedProducts === null ? {} : selectedProducts;
    if (selectedProducts.tillData !== undefined || selectedProducts.tillData !== null) {
      selectedProducts.productsData.map((item, ind) => {
        total = total + item.sale_price;
        discount = discount+ (item.sale_price *item.percentage)/100;
        linesArray.push({
          epc:item.ean,
          itemCode:item.id,
          unitPrice:item.sale_price,
           qty:item.count,
           type:"takeaway",
           discountRule:"",
           promoAmount:"",
           unitDiscount:"",
           promoQty:"",
           offer:false,
           offerName:"",
        })
      });
      doorNo = selectedProducts.tillData[0].storeDetail.storeAddress.storeAddressline1;
      street = selectedProducts.tillData[0].storeDetail.storeAddress.storeAddressline2;
      zipcode = selectedProducts.tillData[0].storeDetail.storeAddress.storePostalCode;
      city = selectedProducts.tillData[0].storeDetail.storeAddress.storeCityName;
      country = selectedProducts.tillData[0].storeDetail.storeAddress.storeCountry;
      state = selectedProducts.tillData[0].storeDetail.storeAddress.storeRegion;
      email = selectedProducts.customerData.customers[0].email;
      name = selectedProducts.customerData.customers[0].name;
      mobile = selectedProducts.customerData.customers[0].mobile;
      decathlonId = selectedProducts.customerData.customers[0].card_number;
    };

    let newArray = [
      {
        "address": [
          {
            "doorNo": `${doorNo}`,
            "street": `${street}`,
            "zipcode": `${zipcode}`,
            "city": `${city}`,
            "country": `${country}`,
            "state": `${state}`,
            "type": "billing"
          }
        ],
        "MyDecathlonId": `${decathlonId}`,
        "email": `${email}`,
        "mobile": `${mobile}`,
        "paymentMethod": `${paymentMode}`,
        "name": `${name}`,
        "salebegintime": `${salebegintime}`,
        "orderDate": `${orderDate}`,
        "totalGrossAmount": (total-discount).toFixed(2),
        "totalDiscount": 0,
        "lines": linesArray,
        "store": `${'7' + terminalId.toString().substring(2, 6) + terminalId.toString().substring(2, 6)}`,
        "terminal": `${terminalId}`,
        "orderNumber": `${terminalId}/${Number(orderId)+1}`,
        "payment": [
          {
            "responemessage": `${response?.responseMessage}`,
            "message": `${response?.message}`,
            "manualverificationid": `${tempTransactionId}`,
            "paymentRefId": `${tempTransactionId}`,
            "ismanual": `${ismanual}`,
            "isphonepe": `${isphonepe}`,
            "cashiername": `${cashierData.Username}`,
            "transactionid": `${transactionId}`,
            "totalamountpaid": total
          }
        ],
        "saleendtime": `${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}`,
        "PaymentDone": "true",
        "cashiername": `${cashierData.Username}`,
        "ebillMode": `${ebillMode === "Mobile Text" ? "mobile" : ebillMode === "Email" ? "email" : ebillMode === "Both Email and Mobile" ? "both" : "printedCopy"}`,
        "Feedback": `${emojiNumber}`
      }
    ];

    let tempArray  = [...orderData,newArray]

    if(status === "offline"){
      await db.syncData.bulkPut(tempArray);
      setTimeout(() => {
        navigate('/thankyou');
      }, 1000);
    }

    Axios({
      url: `${URL.MAIN_URL}/order`,
      method: "POST",
      data:{
        "bills": newArray
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization" : `Basic ${window.btoa(basicAuth)},${token}`
      },
      params:{
        remainingUrl : `/ws/com.promantia.decathlon.custom_selfcheckout.order`,
        "bills": newArray
      }
    }).then((response) => {
      if(response.status === 200){
        setTimeout(() => {
          navigate('/thankyou');
        }, 1000);
      } else {
        message.error("Order is not processed");
      }
    });
  };

  return (
    <div>
      <div className="header-block">
        <Row>
        <Col span={1} >
          </Col>
          <Col span={6} style={{ alignSelf: "center",position:"relative",left:"-2%"}}>
            <p className="user-name">Hey {name}</p>
          </Col>
          <Col span={9} style={{position:"relative",left:"2.6%"}}>
          </Col>
          <Col span={8} className="user-details">
          <img src={Logo} alt="" width={100} />
          </Col>
        </Row>
        <Row style={{ marginTop: "1.5rem" }}>
          <Steps current={3} size="small">
            <Step
              title="Register"
              description={<Badge status="success" text="Completed" />}
            />
            <Step
              title="Scan"
              description={<Badge status="success" text="Completed" />}
            />
            <Step
              title="Payment"
              description={<Badge status="success" text="Completed" />}
            />
            <Step
              title="Feedback"
              description={<Badge status="success" text="In Progress" />}
            />
          </Steps>
        </Row>
      </div>
      <div className="info-block">
        <p className="info-text">
          Please help us with your valusable feedback this will help us to be
          better everytime
        </p>
      </div>
      <div className="payment-method-block">
        <p className="where-would">
				Please Select your Experience
        </p>
        <Row style={{ margin: "auto" }}>
          <Col span={6} />
          {emojiFeedbackList.map((item, index) => (
            <Col
              span={4}
              style={{ paddingRight: 20, cursor: "pointer" }}
              onClick={() => handleBillModeSelection(item.title, index,item.no)}
            >
              <img
                src={Tick}
                alt=""
                className="tick-icon-emoji"
                style={{
                  visibility:
                    selectedValue === item.title ? "visible" : "hidden",bottom:window.innerWidth <= 1024 && window.innerHeight <= 768? "200px" : "205px",left:window.innerWidth <= 1024 && window.innerHeight <= 768? "130px" : "170px"
                }}
              />
              <Card
                bodyStyle={{ padding: 10 }}
                className={
                  selectedValue === item.title
                    ? "emoji-card-active"
                    : "feedback-card"
                }
              >
                <div className="feedback-img-div">
                  <img src={item.img} alt="" className="bill-mode-img" />
                </div>
                <p className="bill-mode-text">{item.title}</p>
              </Card>
            </Col>
          ))}
          <Col span={6} />
        </Row>
      </div>
    </div>
  );
};

export default FeedbackStepTwo;
