import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Avatar, Steps, Badge, Card, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import Logo from "../../assets/SCO_asset/home/decathlon-logo.png";
import LeftArrow from "../../assets/SCO_asset/icons/leftArrow.svg";
import BagOne from "../../assets/SCO_asset/home/bag1.svg";
import BagTwo from "../../assets/SCO_asset/home/bag2.svg";
import RightArrowWhite from "../../assets/SCO_asset/icons/right-arrow-white.svg";
import BagGif from "../../assets/SCO_asset/products/bag-selection.gif";

const { Step } = Steps;

const BagSelection = () => {
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  const [name, setName] = useState("");

  useEffect(() => {
    let tempName = localStorage.getItem("surname");
    setName(tempName);
  }, []);

  const onChange = (value) => {
    console.log("onChange:", current);
    setCurrent(value);
  };

  const handleSubmit = () => {
    navigate('/payment-selection');
  }

  return (
    <div>
      <div className="header-block">
        <Row>
          <Col span={3}>
            <img src={Logo} alt="" />
          </Col>
          <Col span={19} className="user-details">
            <Avatar
              style={{
                backgroundColor: "#0079ba",
              }}
              icon={<UserOutlined />}
            />
          </Col>
          <Col span={2} style={{ alignSelf: "center" }}>
            <p className="user-name">Hey {name}</p>
          </Col>
        </Row>
        <Row style={{ marginTop: "1.5rem" }}>
          <Steps current={current} onChange={onChange} size="small">
            <Step
              title="Register"
              description={<Badge status="success" text="Completed" />}
            />
            <Step
              title="Scan"
              description={<Badge status="success" text="In Progress" />}
            />
            <Step title="Payment" />
            <Step title="Feedback" />
          </Steps>
        </Row>
      </div>
      <div className="info-block">
        <p className="info-text">You can skip the bag selection if you want!</p>
      </div>
      <Row>
        <Col span={12} className="bag-gif-block">
          <p className="suggested">
            {" "}
            <img src={LeftArrow} alt="" /> Back
          </p>
          <img
            className="bag-img"
            src={BagGif}
            width="400px"
            height="400px"
            alt=""
          />
        </Col>
        <Col span={12} className="bag-selection-block">
          <p className="please-select-bag">
            Hey {name}, Please select a carry bag of your choice!
          </p>
          <Row>
            <Col span={12} style={{ paddingRight: 15 }}>
              <Card bodyStyle={{ padding: 15 }} className="suggest-item-card">
                <div className="suggest-img-block">
                  <img src={BagOne} alt="" height="100px" />
                </div>
                <Row style={{ marginTop: 10 }}>
                  <Col span={12}>
                    <p className="suggest-item">Multi-Purpose Paper Bag</p>
                  </Col>
                  <Col span={12}>
                    <p className="suggest-item-price">₹ 10</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Button className="bagSelection-addBtn">
                      <div className="qty-inc-btn">
                        <span>-</span>
                        <span>1</span>
                        <span>+</span>
                      </div>
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={12}>
              <Card bodyStyle={{ padding: 15 }} className="suggest-item-card">
                <div className="suggest-img-block">
                  <img src={BagTwo} alt="" height="100px" />
                </div>
                <Row style={{ marginTop: 10 }}>
                  <Col span={12}>
                    <p className="suggest-item">Multi-Purpose Jute Bag</p>
                  </Col>
                  <Col span={12}>
                    <p className="suggest-item-price">₹ 45</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Button className="bagSelection-addBtn">Add</Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{marginTop: "3rem"}}>
              <Button className="bagSelection-addBtn" onClick={handleSubmit}>
                Proceed to pay &nbsp; <img src={RightArrowWhite} alt="" width="16px"/>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default BagSelection;
