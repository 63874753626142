import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Steps, Badge } from "antd";
import Logo from "../../assets/SCO_asset/home/decathlon-logo.png";
import SuccessGif from "../../assets/SCO_asset/payment/success.gif";


const { Step } = Steps;

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");

  useEffect(() => {
    let tempName = localStorage.getItem("surname");
    setName(tempName);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      let ebill = localStorage.getItem("ebill");
      if (ebill === "true") {
        navigate("/bill-mode");
      } else {
        localStorage.setItem("billMode","Printed Receipt");
        navigate("/feedback-steptwo");
      };
   }, 3000)
}, []);


  return (
    <div>
      <div className="header-block">
        <Row>
        <Col span={1} >
          </Col>
          <Col span={6} style={{ alignSelf: "center",position:"relative",left:"-2%"}}>
            <p className="user-name">Hey {name}</p>
          </Col>
          <Col span={9} style={{position:"relative",left:"2.6%"}}>
          </Col>
          <Col span={8} className="user-details">
          <img src={Logo} alt="" width={100} />
          </Col>
        </Row>
        <Row style={{ marginTop: "1.5rem" }}>
          <Steps current={2} size="small">
            <Step
              title="Register"
              description={<Badge status="success" text="Completed" />}
            />
            <Step
              title="Scan"
              description={<Badge status="success" text="Completed" />}
            />
            <Step
              title="Payment"
              description={<Badge status="success" text="Completed" />}
            />
            <Step title="Feedback" />
          </Steps>
        </Row>
      </div>
      <div className="info-block">
        <p className="info-text">
          Hold on you will be redirected to the next page shortly
        </p>
      </div>
      <Row style={{paddingTop: "1rem"}}>
        <Col span={24} className="payment-method-block">
          <img
            className="card-payment-img"
            src={SuccessGif}
            width="300"
            height="300"
            alt=""
          />
          <p className="payment-successfull">Payment Sucessfull</p>
          <p className="seconds-left">
            Your payments has been made sucessfully and you are being redirected
            to the next page
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default PaymentSuccess;
