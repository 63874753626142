import React,{useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Modal,Button } from "antd";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "antd/dist/antd.css";
import "../../antd.css";
import "../styles.css";
import LoadingGif from "../../assets/SCO_asset/products/Master & Incremental Refresh.gif";
import Logo1 from "../../assets/SCO_asset/home/decathlon-logo.png";
import db from "../../database";
import Axios from "axios";
import moment from "moment";
import URL from "../../app/server.json";

const Loading =()=>{
    const navigate = useNavigate()
    const [loadedMaster, setLoadedMaster] = useState(0);
    const [totalProductsCount, setTotalProductsCount] = useState(0);
    const [totalComboCount,setTotalComboCount] = useState(0);
    const [totalMultiCount,setTotalMultiCount] = useState(0);
    const [comboCount,setComboCount] = useState(0);
    const [multiCount,setMultiCount] = useState(0);
    const [isComboFlag,setIsComboFlag] = useState(false);
    const [isMultiFlag,setIsMultiFlag] = useState(false);
    const [isConfiguration,setIsConfiguration] = useState(false);
    let masterRefresh = localStorage.getItem("isMasterRefresh");
    let incrementalRefresh = localStorage.getItem("isIncremenatalRefresh");
    let index = 0;
    let cashier = JSON.parse(localStorage.getItem('cashierData'));
    let basicAuth = `${cashier.Username}:${cashier.Password}`
  
    useEffect(()=>{
      if(index === 0){
        let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
        selectedProducts = selectedProducts === null ? {} : selectedProducts;
        index++;
        let terminalNumber = localStorage.getItem("terminalNumber");
        let token = localStorage.getItem("token");
        let currentDate = moment(new Date()).format("YYYY-MM-DD");
        let totalCombo
        let totalProducts
        
        const processSync = async () => {
          try {
            if(incrementalRefresh === "true"){
              await incrementalRefreshData();
            }else{
              await deletePreviousData();
              await tillData();
              await totalComboData();
              await getComboData();
              await getMultiBuyData();
              await initialIncrementalRefresh();
            };
          } catch (error) {
            console.log(error);
          }
        };
  
        const deletePreviousData = () => {
          return new Promise(async (deletionSuccess, deletionFailure) => {
            try {
              await db.productData.clear();
              await db.comboData.clear();
              await db.multiBuyData.clear();
              await db.tillData.clear();
              deletionSuccess();
            } catch (error) {
              deletionFailure(error);
            }
          });
        };

        const incrementalRefreshData = async() => {
          return new Promise(async (incrementDataSuccess, incrementDataFailure) => {
            try {
              const response = await Axios({
                url: `${URL.MAIN_URL}/incrementalRefresh`,
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Basic ${window.btoa(basicAuth)},${token}`,
                },
                params: {
                  remainingUrl : `/ws/com.promantia.decathlon.custom_selfcheckout.incremental?terminalname=${terminalNumber}&incrementalTime=${currentDate}%2012:05:56.000`
                }
              })
              const data  = [response.data];
              if(response.status === 200 && data.length > 0){
                localStorage.setItem("isIncremenatalRefresh",false);
                await db.incrementalData.bulkAdd(data);
                await tillData()
              }else{
                incrementDataSuccess();
              }
            } catch (error) {
              console.log("SCO incrementalData: ", " Sync Failed");
              incrementDataFailure(error);
            }
          });
        };
  
        const tillData = () => {
          localStorage.setItem("startTime", moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
          setIsConfiguration(true);
          return new Promise(async (tillDataSuccess, tillDataFailure) => {
            try {
              const response = await Axios({
                url: `${URL.MAIN_URL}/till`,
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  "Connection": "keep-alive",
                  Authorization: `Basic ${window.btoa(basicAuth)},${token}`,
                },
                params: {
                  remainingUrl : `/ws/com.promantia.decathlon.custom_selfcheckout.Configuration?terminalname=${terminalNumber}`
                }
              })
              
              const till  = [response.data];
              if(response.status === 200 && till.length > 0){
                const lastId = await db.tillData.bulkAdd(till);
                selectedProducts.tillData = till;
                localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
                if(incrementalRefresh === "true"){
                  navigate("/admin")
                }
                tillDataSuccess(lastId);
              }else{
                tillDataSuccess();
              }
            } catch (error) {
              console.log("SCO tillData: ", " Sync Failed");
              tillDataFailure(error);
            }
          });
        };
  
        const totalComboData = () => {
          return new Promise(async (totalComboDataSuccess, totalComboDataFailure) => {
            try {
              const response = await Axios({
                url: `${URL.MAIN_URL}/totalCombo`,
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  "Connection": "keep-alive",
                  Authorization: `Basic ${window.btoa(basicAuth)},${token}`,
                },
                params: {
                  remainingUrl : `/ws/com.promantia.decathlon.custom_selfcheckout.refresh?terminalname=${terminalNumber}&Iterator=&discountrule=&startcount=&endcount=`
                }
              });
              const comboData  = [response.data];
              if(response.status === 200 && comboData.length > 0){
                totalCombo = comboData;
                setTotalComboCount(totalCombo[0].DiscountAndPromotions.combodiscountcount)
                setTotalMultiCount(totalCombo[0].DiscountAndPromotions.multibuydiscountcount)
                totalComboDataSuccess(totalCombo);
              }else{
                totalComboDataSuccess();
              }
            } catch (error) {
              console.log("SCO totalCombo Data: ", " Sync Failed");
              totalComboDataFailure(error);
            }
          });
        };

        const getComboData = () => {
          setIsConfiguration(false)
          setIsComboFlag(true);
          let totalComboProducts = Math.floor(totalCombo[0].DiscountAndPromotions.combodiscountcount/50);
          let totdalComboArray =[]
          const comboArray = []
          let flag0 = "N"

          return new Promise(async (comboDataSuccess, comboDataFailure) => {
              for(let x=0;x<=totalComboProducts;x++){
                try{
                  let combodata= await Axios({
                    url: `${URL.MAIN_URL}/combo`,
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json",
                      "Connection": "keep-alive",
                      Authorization: `Basic ${window.btoa(basicAuth)},${token}`,
                    },
                    params: {
                      remainingUrl : `/ws/com.promantia.decathlon.custom_selfcheckout.refresh?terminalname=${terminalNumber}&Iterator=&discountrule=combo&startcount=${50*x}&endcount=50`
                    }
                  })
                  let responseData = combodata.status
                  if(responseData === 200){
                    flag0 = "Y"
                    setComboCount(x*50);
                    let ComboDiscounts = combodata.data.DiscountAndPromotions.ComboDiscounts
                    if(ComboDiscounts.length>0){
                    comboArray.push(...ComboDiscounts)
                    ComboDiscounts.map((item)=>{
                      totdalComboArray.push(...item.OfferProduct)
                    })
                    }
                  }else{
                    flag0 = "N"
                  }
                }catch(error){
                  console.log("------------>error",error)
                  comboDataFailure(error);
                }
                }
              if(flag0 === "Y"){
                const lastId = await db.comboData.bulkAdd(comboArray);
                comboDataSuccess(lastId);
              }else{
                comboDataSuccess();
                console.log("SCO comboData: ", " Sync Failed");
              }
          });
        };

        const getMultiBuyData = () => {
          setIsComboFlag(false)
          setIsMultiFlag(true)
          let totalMultyBuyProducts = Math.floor(totalCombo[0].DiscountAndPromotions.multibuydiscountcount/50)
          const multiBuyArray =[]
          const totalMultiBuyArray =[]
          let flag0 = "N"
          return new Promise(async (multiBuyDataSuccess, multiBuyDataFailure) => {
            for(let y=0;y<=totalMultyBuyProducts;y++){
              try{
                let multiBuyData= await Axios({
                  url: `${URL.MAIN_URL}/multibuy`,
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    "Connection": "keep-alive",
                    Authorization: `Basic ${window.btoa(basicAuth)},${token}`,
                  },
                  params: {
                    remainingUrl : `/ws/com.promantia.decathlon.custom_selfcheckout.refresh?terminalname=${terminalNumber}&Iterator=&discountrule=multibuy&startcount=${y*50}&endcount=50`
                  }
                })
                let responseData = multiBuyData.status
                if(responseData === 200){
                  flag0 = "Y";
                  setMultiCount(y*50);
                let MultiBuyDiscount = multiBuyData.data.DiscountAndPromotions.Multibuy
                  if(MultiBuyDiscount.length>0){
                    multiBuyArray.push(...MultiBuyDiscount)
                    MultiBuyDiscount.map((item)=>{
                      totalMultiBuyArray.push(...item.OfferProduct)
                    })
                    }
                }else{
                  flag0 = "N"
                }
              }catch(error){
                console.log("------------>error",error)
                multiBuyDataFailure(error)
              }

            }
              if(flag0 === "Y"){
                const lastId = await db.multiBuyData.bulkAdd(multiBuyArray);
                multiBuyDataSuccess(lastId);
              }else{
                multiBuyDataSuccess();
                console.log("SCO multiBuy Data: ", " Sync Failed");
              }
          });
        };

        const initialIncrementalRefresh = async() => {
          return new Promise(async (incrementDataSuccess, incrementDataFailure) => {
            try {
              const response = await Axios({
                url: `${URL.MAIN_URL}/incrementalRefresh`,
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Basic ${window.btoa(basicAuth)},${token}`,
                },
                params: {
                  remainingUrl : `/ws/com.promantia.decathlon.custom_selfcheckout.incremental?terminalname=${terminalNumber}&incrementalTime=${currentDate}%2012:05:56.000`
                }
              })
              const data = [response.data];
              if(response.status === 200 && data.length > 0){
                localStorage.setItem("isIncremenatalRefresh",false);
                await db.incrementalData.bulkAdd(data);
                await getTotalProducts();
              }else{
                incrementDataSuccess();
              }
            } catch (error) {
              console.log("SCO incrementalRefreshData: ", " Sync Failed");
              incrementDataFailure(error);
            }

          });
        };

        const getTotalProducts = () => {
          setIsMultiFlag(false)
          return new Promise(async (totalProductsDataSuccess, totalProductsDataFailure) => {
            try {
              const response = await Axios({
                url: `${URL.MAIN_URL}/totalProducts`,
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  "Connection": "keep-alive",
                  Authorization: `Basic ${window.btoa(basicAuth)},${token}`,
                },
                params: {
                  remainingUrl : `/ws/com.promantia.decathlon.custom_selfcheckout.refresh?terminalname=${terminalNumber}&Iterator=&discountrule=DONE&startcount=&endcount=`
                }
              });
              const ProductsData  = [response.data];
              if(response.status === 200 && ProductsData.length > 0){
                totalProducts = ProductsData
                setTotalProductsCount(totalProducts[0].totalProductSize);
                totalProductsDataSuccess(totalProducts);
                await getProducts();
              }else{
                totalProductsDataSuccess();
              }
            } catch (error) {
              console.log("SCO totalProducts Data: ", " Sync Failed");
              totalProductsDataFailure(error);
            }

          });
        };

        const getProducts = () => {
          let totalProductData = Math.floor(totalProducts[0].totalProductSize/1000);
          const data = []
          let flag0 = "N"
          return new Promise(async (productDataSuccess, productDataFailure) => {
            for(let i=0;i<=totalProductData;i++){
              try{
                let productData= await Axios({
                  url: `${URL.MAIN_URL}/masterRefresh`,
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    "Connection": "keep-alive",
                    Authorization: `Basic ${window.btoa(basicAuth)},${token}`,
                  },
                  params: {
                    remainingUrl : `/ws/com.promantia.decathlon.custom_selfcheckout.refresh?terminalname=${terminalNumber}&Iterator=${1000*i}&discountrule=DONE&startcount=&endcount=`
                  }
                })
                let responseData = productData.status
                if(responseData === 200){
                  try{
                    flag0 = "Y"
                    setLoadedMaster(i*1000);
                    localStorage.setItem("order_lastassignednum", productData.data.order_lastassignednum);
                    productData.data.Product.map((ele)=>{
                      data.push(ele)
                    })
                  }catch(err){
                    flag0 = "N"
                    console.log(err)
                  }
                }
              }catch(error){
                console.log("------------>error",error)
                productDataFailure(error)
              }

            }
              if(flag0 === "Y"){
                const lastId = await db.productData.bulkPut(data);
                await getToken();
                productDataSuccess(lastId);
              }else{
                productDataSuccess();
                console.log("SCO Products Data: ", " Sync Failed");
              }
          });
        };

        const getToken = () => {
          return new Promise(async (tokenDataSuccess, tokenDataFailure) => {
            
            try {
              const response = await Axios({
                url: `${URL.MAIN_URL}/token`,
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Basic Q2E3YmZmZWI4ZDY2NzQxZjI4OTM0OGFkNGQxOTcwOWRkYWY2NDA5YjY6U2t5ZVE0SEo5cmFHS3RJMWpNblg1Z1NxUmo0bnUwdG1LaVNzOEtIdVFzT1R5anF6VmhtblNacVJGbHZvRmhycA==",
                },
                params:{
                  grant_type:"client_credentials"
                }
              });
              const tokenData  = [response.data];
              if(response.status === 200 && tokenData.length > 0){
                localStorage.setItem("isMasterRefresh",false);
                let token = localStorage.setItem("crm_token", response.data.access_token);
                tokenDataSuccess(token);
                if(masterRefresh === "true"){
                navigate("/admin")
                }else{
                  localStorage.setItem("endTime", moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
                navigate("/customer-login");
                }
              }else{
                tokenDataSuccess();
              }
            } catch (error) {
              console.log("SCO Token Data: ", " Sync Failed");
              tokenDataFailure(error);
            }
          });
        };
        processSync();
      }  
    },[]);

    return (
        <div style={{height:"100%",width:"100%"}}> 
         <div className="header-block">
        <Row>
          <Col span={3}>
            <img src={Logo1} alt="" />
          </Col>
        </Row>
      </div>
      <div style={{marginTop:"50px"}}>
        <Row>
          <Col span={1}/>
          <Col span={22} style={{height:"480px",width:"100%",background: "rgba(231, 246, 245, 1)",borderRadius:"15px"}}>
              <Row>
                <Col span={8}/>
                <Col span={8} style={{display:"flex",justifyContent:"center",flexDirection:"column"}}> 
                <img src={LoadingGif}/>
                {incrementalRefresh === "false" ? 
                  isConfiguration ?
                  <>
                  <p style={{fontWeight:"600",fontSize:"15px",textAlign:"center"}}> </p>
                  <p style={{fontWeight:"600",fontSize:"18px",textAlign:"center"}}>Configurations refresh is in progress!</p>
                  </>
                  :
                  isComboFlag ? 
                  <>
                  <p style={{fontWeight:"600",fontSize:"15px",textAlign:"center"}}>{comboCount} products loaded from {totalComboCount}</p>
                  <p style={{fontWeight:"600",fontSize:"22px",textAlign:"center"}}>Combo refresh is in progress!</p>
                  </>
                  :
                  isMultiFlag ? 
                  <>
                  <p style={{fontWeight:"600",fontSize:"15px",textAlign:"center"}}>{multiCount} products loaded from {totalMultiCount}</p>
                  <p style={{fontWeight:"600",fontSize:"22px",textAlign:"center"}}>Multibuy refresh is in progress!</p>
                  </>
                  :
                  <>
                  <p style={{fontWeight:"600",fontSize:"15px",textAlign:"center"}}>{loadedMaster} products loaded from {totalProductsCount}</p>
                  <p style={{fontWeight:"600",fontSize:"22px",textAlign:"center"}}>Master refresh is in progress!</p>
                  </>
                  : 
                  <p style={{fontWeight:"600",fontSize:"22px",textAlign:"center"}}>Incremental refresh is in progress!</p>
                }
                </Col>
                <Col span={8}/>
              </Row>
          </Col>
          <Col span={1}/>
        </Row>
      </div>
        </div>
    )
}
export default Loading
