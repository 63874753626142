import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Avatar, Steps, Badge, Card, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import Logo from "../../assets/SCO_asset/home/decathlon-logo.png";
import Email from "../../assets/SCO_asset/ebill-buttons/mail.svg";
import MobileText from "../../assets/SCO_asset/ebill-buttons/mobile-text.svg";
import EmailMobileText from "../../assets/SCO_asset/ebill-buttons/email-mobiletext.svg";
import PrintReceipt from "../../assets/SCO_asset/ebill-buttons/print-receipt.svg";
import RightArrowWhite from "../../assets/SCO_asset/icons/right-arrow-white.svg";
import Tick from "../../assets/SCO_asset/ebill-buttons/tick.svg";
import _ from "lodash";

const { Step } = Steps;

const BillMode = () => {
  const navigate = useNavigate();
	const [selectedValue, setSelectedValue] = useState("");
  const [name, setName] = useState("");

  const status = navigator.onLine ? "online" : "offline";

  useEffect(() => {
    let tempName = localStorage.getItem("surname");
    setName(tempName);
  }, []);

	const billModesList = [
		{
			title: "Email",
			img: Email
		},
		{
			title: "Mobile Text",
			img: MobileText
		},
		{
			title: "Both Email and Mobile",
			img: EmailMobileText
		},
		{
			title: "Printed Receipt",
			img: PrintReceipt
		}
	];

	const handleBillModeSelection = (value, cardIndex) => {
		const selectedBillModeIndex = _.findIndex(billModesList, ["title", value]);
		if (selectedBillModeIndex === cardIndex) {
			setSelectedValue(value)
      localStorage.setItem("billMode",value)
      setTimeout(()=>{
        navigate("/feedback-steptwo");
      },500)
		}
	};


  return (
    <div>
      <div className="header-block">
        <Row>
          <Col span={3}>
            <img src={Logo} alt="" />
          </Col>
          <Col span={6}>
          </Col>
          <Col span={13} className="user-details">
            <Avatar
              style={{
                backgroundColor: "#0079ba",
              }}
              icon={<UserOutlined />}
            />
          </Col>
          <Col span={2} style={{ alignSelf: "center" }}>
            <p className="user-name">Hey {name}</p>
          </Col>
        </Row>
        <Row style={{ marginTop: "1.5rem" }}>
          <Steps current={3} size="small">
            <Step
              title="Register"
              description={<Badge status="success" text="Completed" />}
            />
            <Step
              title="Scan"
              description={<Badge status="success" text="Completed" />}
            />
            <Step
              title="Payment"
              description={<Badge status="success" text="Completed" />}
            />
            <Step title="Feedback" />
          </Steps>
        </Row>
      </div>
      <div className="info-block">
        <p className="info-text">
          Please help us with your valusable feedback this will help us to be
          better everytime
        </p>
      </div>
      <div className="payment-method-block">
        <p className="where-would">
          Where would you prefer to recieve the E-Bill?
        </p>
        <Row style={{ margin: "auto" }}>
          <Col span={4} />
					{billModesList.map((item, index) => (
						<Col
            span={4}
            style={{ paddingRight: 20, cursor:status === "offline" ? item.title !== "Printed Receipt" ? "not-allowed" : "pointer" : "pointer"}}
            onClick={status === "offline" ? item.title !== "Printed Receipt" ? "": () => handleBillModeSelection(item.title, index) : () => handleBillModeSelection(item.title, index)}
          >
            <img
              src={Tick}
              alt=""
              className="tick-icon"
              style={{visibility:status === "online" ?  selectedValue === item.title ? "visible" : "hidden" : "hidden" ,bottom:window.innerWidth <= 1024 && window.innerHeight <= 768? "192px" : "205px",left:window.innerWidth <= 1024 && window.innerHeight <= 768? "125px" : "170px"}}
            />
            <Card
              bodyStyle={{ padding: 7 }}
              className={
                selectedValue === item.title ? "billmode-card-active" : "billmode-card"
              }
            >
              <div className="billmode-img-div">
                <img src={item.img} alt="" className="bill-mode-img" />
              </div>
              <p className="bill-mode-text">{item.title}</p>
            </Card>
          </Col>
					))}
          <Col span={4} />
        </Row>
      </div>
    </div>
  );
};

export default BillMode;
