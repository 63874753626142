import React,{useState,useEffect,useRef} from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Typography, Form, Input, Button, message,Spin } from "antd";
import { Carousel } from "react-responsive-carousel";
import { LoadingOutlined } from "@ant-design/icons";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "antd/dist/antd.css";
import "../../antd.css";
import "../styles.css";
import SignupGif from "../../assets/SCO_asset/login-gif/signup-1.gif";
import Announcement from "../../assets/SCO_asset/login-gif/announcement.svg";
import Axios from "axios";
import db from "../../database";
import URL from "../../app/server.json";
import moment from "moment";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

const { Title } = Typography;
const { Paragraph } = Typography;

const CustomerSignup = () => {
  const navigate = useNavigate();
  const [tillData,setTilldata] = useState([]);
  const [customerData,setCustomerData] = useState({});
  const [loading, setLoading] = useState({ status: false, message: "" });
  const [userName, setUserName] = useState("");
  const [pincodeNo, setPincodeNo] = useState("");
  const [keyboard, setKeyboard] = useState(1);
  const [layout, setlayout] = useState("default");
  const [phoneNo,setPhoneNo] = useState("");
  const [email,setEmail] = useState("");

  
  useEffect(() => {
    (async () => {
      let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
      selectedProducts = selectedProducts === null ? {} : selectedProducts;
      selectedProducts.productsData = [];
      localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
      const data = await db.tillData.toArray();
      setTilldata(data[0]);
      const tempData = JSON.parse(localStorage.getItem("customerData"))
      if(tempData !== undefined || tempData !== null || tempData !== ""){
        setCustomerData(JSON.parse(localStorage.getItem("customerData")))
        setPhoneNo(tempData?.phoneNo);
        setEmail(tempData?.email);
      }
    })();
  }, []);
  
  const handleHome = async() => {
    if(phoneNo.length === 10 || email.includes("gmail")&&email.includes("@")&&email.includes(".com") || email.includes("yahoo")&&email.includes("@")&&email.includes(".com")|| email.includes("hotmail")&&email.includes("@")&&email.includes(".com")){
      await getToken();
      const token = localStorage.getItem("crm_token");
      let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
      selectedProducts = selectedProducts === null ? {} : selectedProducts;  
      if(pincodeNo.length === 6){
        setLoading({ status: true, message: "" });
        Axios({
          url: `${URL.MAIN_URL}/customerLogin`,
          method: "POST",
          data:{
            "card_number": "",
            "email": `${email}`,
            "mobile": `${phoneNo}`
            },
          headers: {
            "Content-Type": "application/json",
            "Connection": "keep-alive",
            'x-api-key':"609ed4ce-1869-4059-a6eb-3c5e40896b01",
            Authorization: `EMPLOYEE ${token}`,
          },
          params:{
            "card_number": "",
            "email": `${email}`,
            "mobile": `${phoneNo}`
          }
        }).then(async(response)=>{
          if(response.status === 200){
            let obj = {
              abo_fid:false,
              sports:null,
              stores:{
                type_third_usual:7,
                num_third_usual:1449,
                under_num_third_usual:1449
                },
              contact:{
                  mobile_valid:true,
                  email_valid:true,
                  mobile_country_code:"IN",
                  email:`${email}`,
                  mobile:`${phoneNo}`
                      },
              optin:{
                  partner:false,
                  decathlon:false
                      },
              billing_address:{
                  additional_number:" ",
                  country_code:`${tillData.crmConfigurations.ppays}`,
                  country_code_geo_id:"IND",
                  line1:"one",
                  postal_code:`${pincodeNo}`,
                  city:`${tillData.storeDetail.storeAddress.storeCityName}`,
                  province:`${tillData.storeDetail.storeAddress.storeRegion}`,
                  postal_code_geo_id:`IN-${pincodeNo}-${tillData.storeDetail.storeAddress.storeCityName}`,
                  province_geo_id:`IN-${tillData.storeDetail.storeAddress.storePostalCode}`,
                  city_geo_id:`IN-${tillData.storeDetail.storeAddress.storePostalCode}-${tillData.storeDetail.storeAddress.storeCityName}`
                          },
              identity_individual:{
                  member_type:2,
                  national_id:"",
                  country_code:"IN",
                  language_code:"EN",
                  birthdate:null,
                  id_person:null,
                  prefix:"",
                  surname:"",
                  name:"sun",
                  sexe:null,
                  name2:"",
                  national_id_type:""
                }
              }
              Axios({
                  url: `https://api-global.preprod.decathlon.net/customers/sg/v1/customer_creation/smart_individual?ppays=IN&state=7&client_id=open_bravo_in`,
                  method: "POST",
                  data:obj,
                  params:obj,
                  headers: {
                    "Content-Type": "application/json",
                    "Connection": "keep-alive",
                    'x-api-key':"609ed4ce-1869-4059-a6eb-3c5e40896b01",
                    Authorization: `EMPLOYEE ${token}`,
                  },
                }).then((data)=>{
                  if(data.status === 200){
                    let currentDate = moment(new Date()).format("YYYY-MM-DD");
                    let customerData = {phoneNo: phoneNo,email:email}
                    localStorage.setItem('customerData', JSON.stringify(customerData))
                    let currentDateTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
                    localStorage.setItem("signupFlag",true);
                    selectedProducts.customerSignupData = response.data;
                    localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
                    localStorage.setItem('orderDate', currentDate);
                    localStorage.setItem('salebegintime', currentDateTime);
                    localStorage.setItem('surname', userName);
                    setLoading({ status: false, message: "" });
                    navigate("/home");
                  }else{
                    setLoading({ status: false, message: "" });
                    message.error("something is wrong");
                  }
                }).catch(error=>{
                  console.log(error)
                  setLoading({ status: false, message: "" });
                  message.error(error.response.data.error.message);
                })
          }
        })
      }else{
        message.error("Kindly Provide 6 digit Pin code Number");
      }
    }
  }

  const getToken = () => {
    setLoading({ status: true, message: "" });
    return new Promise(async (tokenDataSuccess, tokenDataFailure) => {
      try {
        const response = await Axios({
          url: `${URL.MAIN_URL}/token`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic Q2E3YmZmZWI4ZDY2NzQxZjI4OTM0OGFkNGQxOTcwOWRkYWY2NDA5YjY6U2t5ZVE0SEo5cmFHS3RJMWpNblg1Z1NxUmo0bnUwdG1LaVNzOEtIdVFzT1R5anF6VmhtblNacVJGbHZvRmhycA==",
          },
          params:{
            grant_type:"client_credentials"
          }
        });
        const tokenData  = [response.data];
        if(response.status === 200 && tokenData.length > 0){
          localStorage.setItem("isMasterRefresh",false);
          let token = localStorage.setItem("crm_token", response.data.access_token);
          tokenDataSuccess(token);
            localStorage.setItem("endTime", moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
        }else{
          setLoading({ status: false, message: "" });
          tokenDataSuccess();
        }
      } catch (error) {
        setLoading({ status: false, message: "" });
        console.log("SCO Token Data: ", " Sync Failed");
        tokenDataFailure(error);
      }
    });
  };

  const onKeyPress = (button) => {
    if(keyboard === 1){
      if (button === "{bksp}") {
        setPhoneNo(phoneNo.slice(0, -1));
      };
      if (button === "{shift}" || button === "{lock}") handleShift();
      if(button !== "{shift}" && button !== "{lock}" && button !== "{bksp}" & button !== "{tab}"&& button !== "{enter}" & button !== "{space}"){
        let text = `${phoneNo}${button}`
        setPhoneNo(text.replace(/\D/g, "").trim());
      }
    }else if(keyboard === 2){
      if (button === "{bksp}") {
        setEmail(email.slice(0, -1));
      };
      if (button === "{shift}" || button === "{lock}") handleShift();
      if(button !== "{shift}" && button !== "{lock}" && button !== "{bksp}" & button !== "{tab}"&& button !== "{enter}" & button !== "{space}"){
        let text = `${email}${button}`
        setEmail(text.trim());
      }
    }else if(keyboard === 3){
      if (button === "{bksp}") {
        setUserName(userName.slice(0, -1));
      };
      if (button === "{shift}" || button === "{lock}") handleShift();
      if(button !== "{shift}" && button !== "{lock}" && button !== "{bksp}" & button !== "{tab}"&& button !== "{enter}" & button !== "{space}"){
        let text = `${userName}${button}`
        setUserName(text.trim());
      }

    }else if (keyboard === 4){
      if (button === "{bksp}") {
        setPincodeNo(pincodeNo.slice(0, -1));
      };
      if (button === "{shift}" || button === "{lock}") handleShift();
      if(button !== "{shift}" && button !== "{lock}" && button !== "{bksp}" & button !== "{tab}"&& button !== "{enter}" & button !== "{space}"){
        let text = `${pincodeNo}${button}`
        setPincodeNo(text.replace(/\D/g, "").trim());
      }
    }

  };

  const handleShift = () => {
    const layout1 = layout;
    setlayout(layout1 === "default" ? "shift" : "default");
  };

  const handlePhone = (event) => {
    const inp = event.target.value;
    if (inp.length <= 10 && /^[6789]/.test(inp)) {
      setPhoneNo(inp.replace(/\D/g, ""));
    }else if(!/^[6789]/.test(inp)&&inp.length === 0){
      setPhoneNo("");
    }else{
      message.error("Mobile Number Should Start with 6/7/8/9")
    }
  };

  const handleEmail = (ev) => {
    setEmail(ev.target.value);
  };
  
  const handleUserName = (ev) => {
    if(ev.target.value.match(/^[A-Za-z]+$/)){
    setUserName(ev.target.value);
    }else{

    }
  };

  const handlePincode= (event) => {
    const inp = event.target.value;
    setPincodeNo(inp.replace(/\D/g, ""));
  };

  const antIcon = <LoadingOutlined style={{ fontSize: "24px" }} spin />;
  return (
    <div>
      <Row>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
          <div className="adv-block">
            <div className="adv-block-div">
              <p className="shopping-with">
                Shopping with us has become more easier
              </p>
              <Carousel
                autoPlay={true}
                infiniteLoop={true}
                showArrows={false}
                showStatus={false}
                showIndicators={true}
                showThumbs={false}
              >
                <div>
                  <img src={SignupGif} alt="" className="adv-gif" />
                  <p className="get-all">
                    Get all the things you want in your cart and visit to the
                    self check out near the counter
                  </p>
                </div>
                <div>
                  <img src={SignupGif} alt="" className="adv-gif" />
                  <p className="get-all">
                    Scan each item of your items by just putting them into the
                    basket and proceed to pay
                  </p>
                </div>
                <div>
                  <img src={SignupGif} alt="" className="adv-gif" />
                  <p className="get-all">
                    Walk away with your favourite purchases, we are happy to see
                    you independent
                  </p>
                </div>
              </Carousel>
            </div>
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
          <Row className="inputs-row" justify="center">
            <Col span={2}></Col>
            <Col span={20}>
              <Row justify="center" className="info-div" style={{ marginBottom : "20px" }}>
                <p className="seeking">
                  <img src={Announcement} alt="" /> &nbsp; Seeking your
                  Attention
                </p>
                <p className="for-any-help">
                  For any help and checkout problem or Payment strucked issues,
                  please reach out to our store person and they will help you
                  out
                </p>
              </Row>
            </Col>
            <Col span={2}></Col>
            <Col span={5}></Col>
            <Col span={14}>
            <Spin indicator={antIcon} spinning={loading.status} tip={loading.message}>
              <Row justify="center">
                <Title level={3} style={{ color: "#333333" }}>
                Welcome to Decathlon!
                </Title>
                <Paragraph style={{ color: "rgba(0, 0, 0, 0.4)" }}>
                Please register to your account and you can proceed with the checkout
                </Paragraph>
                <Input readOnly={customerData?.phoneNo === "" ? false : true} style={{ width: 400, marginTop: "15px" }} value={phoneNo} onChange={handlePhone} onFocus={() => { setKeyboard(1) }} onClick={() => { setKeyboard(1) }} placeholder="Phone Number" className="user-input" />
                <Input readOnly={customerData?.email === "" ? false : true} style={{ width: 400, marginTop: "15px" }} value={email} onChange={handleEmail} onFocus={() => { setKeyboard(2) }} onClick={() => { setKeyboard(2) }} placeholder="Email" className="user-input" />
                <Input style={{ width: 400, marginTop: "15px" }} value={userName} onChange={handleUserName} onFocus={() => { setKeyboard(3) }} onClick={() => { setKeyboard(3) }} placeholder="Full Name" className="user-input" />
                <Input style={{ width: 400, marginTop: "15px" }} value={pincodeNo} onChange={handlePincode} onPressEnter={handleHome} onFocus={() => { setKeyboard(4) }} onClick={() => { setKeyboard(4) }} placeholder="Pincode" className="user-input" />
                <span style={{color:"red",position:"absolute",fontSize:"3vh",bottom:"29%",left:"-2%"}}>*</span>
                <Button style={{ width: 400, marginTop: "15px", marginBottom: "50px" }} onClick={handleHome} htmlType="submit" className="continue-btn">Continue</Button>
              </Row>
            </Spin>
            </Col>
            <Col span={5}></Col>
            <Col span={22}>
            <Keyboard
                  layoutName={layout}
                  onKeyPress={onKeyPress}
                  display={{
                    '{bksp}' : 'backspace',
                    '{tab}' : 'tab',
                    '{lock}' : 'lock',
                    '{enter}' : 'enter',
                    '{shift}' : 'shift',
                    '{space}' : ' ',
                  }}
                />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CustomerSignup;
