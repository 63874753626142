import React,{useEffect,useState} from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button } from "antd";
import Logo from "../../assets/SCO_asset/home/decathlon-logo.png";
import RightArrowWhite from "../../assets/SCO_asset/icons/right-arrow-white.svg";
import Penguine from "../../assets/SCO_asset/home/thankyou.gif";
import Barcode from 'react-barcode';
import moment from "moment";
import "./printReceipt.css";

const Thankyou = () => {
  const navigate = useNavigate();
  const [isVisibleFlag,setIsVisibleFlag] = useState(false);
  const [isQty,setIsQty] = useState(0);
  const [isTotal,setIsTotal] = useState(0);
  const [isTaxTotal,setIsTaxTotal] = useState(0);
  const [username, setUserName] = useState("");
  const [cashierData, setCashierData] = useState();
  const [discount, setDiscount] = useState(0);

  useEffect(() => {
    let tempName = localStorage.getItem("surname");
    setUserName(tempName);
  }, []);

  let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
  let terminalNumber = localStorage.getItem("terminalNumber");
  let orderId = Number(localStorage.getItem('order_lastassignednum')) + 1;
  let orderDate = localStorage.getItem("orderDate")
  let saleendtime = localStorage.getItem("saleendtime")

  useEffect( () => {
    let ebillMode = localStorage.getItem("billMode");
    let total =0;
    let disc = 0;
    let qty =0;
    let cashier = JSON.parse(localStorage.getItem('cashierData'));
    setCashierData(cashier);
    let totalTaxAmount = 0;
    selectedProducts = selectedProducts === null ? {} : selectedProducts;
    if (selectedProducts.tillData !== undefined || selectedProducts.tillData !== null) {
      selectedProducts.productsData.map((item, ind) => {
        total = total + item.sale_price;
        disc = disc+ (item.sale_price *item.percentage)/100;
        qty = qty+ item.count;
        let txaValue = 0;
        item?.tax.map((e)=>{
          txaValue = txaValue +e?.rate
        });
        item.txaValue = txaValue
      });
      selectedProducts.productsData.map((item) => {
        let taxTotal = 0;
        item?.tax.map((e)=>{
          taxTotal = taxTotal + (total-(total*100/(100+item.txaValue)))/2
        });
        totalTaxAmount = totalTaxAmount+ taxTotal;
      })
      setIsTaxTotal(totalTaxAmount.toFixed(2))
      setIsQty(qty);
      setIsTotal(total);
      setDiscount(disc);
    };
    
    if(ebillMode === "Printed Receipt"){
      setIsVisibleFlag(false);
      setTimeout(()=>{
        window.print();
    },300)
    }else{
      navigate("/customer-login");
      setIsVisibleFlag(true);
    };
  }, []);

  window.onafterprint = (event) => {
    localStorage.setItem("order_lastassignednum", orderId);
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    selectedProducts = selectedProducts === null ? {} : selectedProducts;
    selectedProducts.productsData = [];
    localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
    localStorage.removeItem("tempTransactionId");
    localStorage.removeItem("paymentMode");
    localStorage.removeItem("surname");
    localStorage.removeItem("response");
    localStorage.removeItem("billMode");
    localStorage.removeItem("transactionId");
    localStorage.removeItem("ismanual");
    localStorage.removeItem("isphonepe");
    localStorage.removeItem("salebegintime");
    localStorage.removeItem("saleendtime");
    localStorage.removeItem("orderDate");
    navigate("/customer-login");
  };

  const handleHome = () => {
    localStorage.setItem("order_lastassignednum", orderId+1);
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    selectedProducts = selectedProducts === null ? {} : selectedProducts;
    selectedProducts.productsData = [];
    localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
    localStorage.removeItem("tempTransactionId");
    localStorage.removeItem("paymentMode");
    localStorage.removeItem("surname");
    localStorage.removeItem("response");
    localStorage.removeItem("billMode");
    localStorage.removeItem("transactionId");
    localStorage.removeItem("ismanual");
    localStorage.removeItem("isphonepe");
    localStorage.removeItem("salebegintime");
    localStorage.removeItem("saleendtime");
    localStorage.removeItem("orderDate");
    navigate('/customer-login');
  };

  const PrintReceipt = () => {
    return(
        <>
    <div className="parent-container" >
    <div className="container">
    <div className="item">
        <img src={Logo} />
      </div>
      <div className="item">
        *************Test Bill************
      </div>
      <div className="item">
        ORIGINAL
      </div>
      <div className="item">
        Store Email
      </div>
      <div className="item">
      {selectedProducts.tillData[0].storeDetail.storeEmailId}
      </div>
      <div className="item">
        Store Manager Emal
      </div>
      <div className="item">
      {selectedProducts.tillData[0].storeDetail.storeManagerMailId}
      </div>
      <div className="item">
      {selectedProducts.tillData[0].storeDetail.storePhoneNo}
      </div>
      <div className="item">
      {selectedProducts.tillData[0].storeDetail.storeTime}
      </div>
      <div className="item">
      {terminalNumber}
      </div>
      <div className="item">
        Place of Supply:
      </div>
      <div className="item">
        {selectedProducts.tillData[0].storeDetail.storeAddress.storeAddressline1}
        {selectedProducts.tillData[0].storeDetail.storeAddress.storeAddressline2}
        {selectedProducts.tillData[0].storeDetail.storeAddress.storeCityName}
        {selectedProducts.tillData[0].storeDetail.storeAddress.storeRegion}
        {selectedProducts.tillData[0].storeDetail.storeAddress.storePostalCode}
        {selectedProducts.tillData[0].storeDetail.storeAddress.storeCountry}
      </div>
      <div className="item">
        GSTIN: {selectedProducts.tillData[0].storeDetail.storeGstin}
      </div>
      <div className="item">
        FSSAI No: {selectedProducts.tillData[0].storeDetail.fssaiNo}
      </div>
      <div className="item-container">
        <div className="sub-item">
          Date: {moment(orderDate).format("DD-MM-YYYY")}
        </div>
        <div className="sub-item-second">
          POS: {terminalNumber}
        </div>
      </div>
      <div className="item-container">
        <div className="sub-item">
          Cashier: {cashierData?.Username}
        </div>
        <div className="sub-item-second">
          Time: {moment(saleendtime).format("HH:mm:ss")}
        </div>
      </div>
      <div className="item">
        Bill: {terminalNumber}/{Number(orderId)}
      </div>
      <div className="item">
        DecathlonId: {selectedProducts.customerData.customers[0].card_number}
      </div>
      <div className="item">
        Customer Name: {localStorage.getItem("surname")}
      </div>
      <div className="item">
        Mobile No.: {selectedProducts.customerData.customers[0].mobile}
      </div>
      <div className="item">
        Email: {selectedProducts.customerData.customers[0].email}
      </div>
      <div className="item-container">
        <div className="sub-item.content">
          Item-code-Description
        </div>
      </div>
      <div className="item-container">
        <div className="item-title">
          HSN
        </div>
        <div className="item-title">
          Qty
        </div>
        <div className="item-title">
          Price
        </div>
        <div className="item-title">
          Total
        </div>
      </div>
      <hr className="dash" />
      <div >
      {selectedProducts.productsData.map((item)=>{
                      if(!item.name.includes("Bag")){
                        return <>
                        <div className="item-container">
                              <div className="sub-item.content">
                              {item.id} - {item.name}
                              </div>
                            </div>
                            <div className="item-container">
                              <div className="item-title">
                              {item.HSN_value}
                              </div>
                              <div className="item-title">
                              {item?.count}
                              </div>
                              <div className="item-title">
                              {(item.sale_price).toFixed(2)}
                              </div>
                              <div className="item-title">
                              {(item.sale_price).toFixed(2)}
                              </div>
                            </div>
                            <div className="item-container" >
                              <div className="offername">
                                --{item?.discountName}
                              </div>
                              <div className="offeramount" >
                                &nbsp;{item.percentage!==0? -((item.sale_price *item.percentage)/100).toFixed(2) : ""}
                              </div>
                            </div>
                            <br/>
                        </>
                      }
       })}
    </div>
    <hr className="dash" />
      <div className="item-container">
        <div className="item-total">
          Total Qty
        </div>
        <div className="item-total"></div>
        <div className="item-total">
         Grand Total
        </div>
      </div>
      <hr className="dash" />
      <div className="item-container">
        <div className="item-total">
          {isQty}
        </div>
        <div className="item-total"></div>
        <div className="item-total">
          INR {(isTotal - discount).toFixed(2)}
        </div>
      </div>
      <hr className="dash" />
      <div className="item-container">
        <div className="item-total">
          Mode of payment
        </div>
      </div>
      <hr className="dash" />
      <div className="item-container">
        <div className="item-total">
        {localStorage.getItem("paymentMode")}
        </div>
        <div className="item-total"></div>
      </div>
      <div className="item-container">
        <div className="item-name">
        {localStorage.getItem("transactionId") !== "" ? localStorage.getItem("transactionId")  : localStorage.getItem("tempTransactionId")}
         </div>
         <div className="item-price">
           {(isTotal - discount).toFixed(2)}
         </div>
      </div>
      <div className="item-container">
        <div className="item-name">
         Change
        </div>
        <div className="item-price">
          INR 0.00
        </div>
      </div>
      <div className="item-container">
        <div className="item-title">
          Category
        </div>
        <div className="item-title">
          Rate
        </div>
        <div className="item-title">
          Base
        </div>
        <div className="item-title">
          Amt
        </div>
      </div>
      <br/>
      <hr className="dash" />
      {selectedProducts.productsData.map((item)=>{
                     return item.tax.map((e)=>{
                          return( 
                                  <div className="item-container" >
                                  <div className="item-title">
                                  {e.name}
                                  </div>
                                  <div className="item-title">
                                  {(e.rate).toFixed(2)}%
                                  </div>
                                  <div className="item-title">
                                  {(isTotal*100/112).toFixed(2)}
                                  </div>
                                  <div className="item-title">
                                  {((isTotal-(isTotal*100/112))/2).toFixed(2)}
                                  </div>
                                </div>
                )
             })
         })}
      <hr className="dash" />
      <div className="item-container">
        <div className="item-total">
         Total
        </div>
        <div className="item-total"></div>
        <div className="item-total">
            {isTaxTotal}
        </div>
      </div>
    
      <div className="barcode-container" >
        <div className="bar-code-item" >
        <Barcode  value={`${terminalNumber}/${orderId+1}`} height={"60px"} width={1.3} font={"14px"}/>
        </div>
      </div>
      <div className="item">
        Decathlon Sports India Pvt Ltd
      </div>
      <div className="item">
     Address: Survey No. 78/10 A20-Chikkajala Village, Bellary Road,
     Bengaluru-562157 C:N: U72200KA2004PTC033858
     +917676798989/ Fax: 08028467111
      </div>
      <div className="item">
        For Feedback  :  vijoy.nair@decathlon.com
      </div>
      <div className="item">
        The products purchased are not intended for resale,
        therefore the customer will not be eligible for GST
        credit.
      </div>
      <div className="item">
        Visit us at wwww.decathlon.in to know more
        about us and terms of this sale
      </div>
      <div className="item">
        Kindly note that it is system generated document
        and hence does not require any signature.
      </div>
    
    </div>
    <div className="item">
      *************Test Bill************
    </div>
    </div>
        </>
    )
  };

  return (
    <div>
      {isVisibleFlag ? 
      <>
      <div className="header-block">
        <Row>
        <Col span={1} >
          </Col>
          <Col span={6} style={{ alignSelf: "center",position:"relative",left:"-2%"}}>
            <p className="user-name">Hey {username}</p>
          </Col>
          <Col span={9} style={{position:"relative",left:"2.6%"}}>
          </Col>
          <Col span={8} className="user-details">
          <img src={Logo} alt="" width={100} />
          </Col>
        </Row>
      </div>
      <div className="payment-method-block">
        <img src={Penguine} alt="" width={250} height={250}/>
        <p className="thank-you">
        Thank you for shopping with us!
        </p>
        <Row>
          <Col span={24}>
            <Button className="proceed-nextBtn" onClick={handleHome}>
              Go to Cart &nbsp;{" "}
              <img src={RightArrowWhite} alt="" width="16px" />
            </Button>
          </Col>
        </Row>
      </div>
      </>
      : 
      <PrintReceipt/>
      }
   </div>
  );
};

export default Thankyou;
