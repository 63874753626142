import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Input, Steps, Badge, Card, Button, Spin, message, Modal } from "antd";
import { SearchOutlined,LoadingOutlined } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";
import Logo from "../../assets/SCO_asset/home/decathlon-logo.png"; 
import RightArrow from "../../assets/SCO_asset/icons/right-arrow.svg";
import OfferIcon from "../../assets/SCO_asset/products/offer-icon.svg";
import CloseIcon from "../../assets/SCO_asset/home/close.svg";
import db from "../../database";
import ScannerComponent from "../scanner";
import suggestData from "./suggestedItems.json";
import loadingGif from "../../assets/SCO_asset/home/loading.gif";
import bag1 from "../../assets/SCO_asset/home/bag1.svg";
import bag2 from "../../assets/SCO_asset/home/bag2.svg";
import cartGif from "../../assets/SCO_asset/home/Kart Droping.gif";
import noImage from "../../assets/SCO_asset/home/No Image.png";
import { v4 as uuid } from "uuid";
import moment from "moment";
import Axios from "axios";
import URL from "../../app/server.json";

const { Step } = Steps;

const Home = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState({ status: false, message: "" });
  const [productNo,setProductNo] = useState({startIndex:0,endIndex:5})
  const [tempArray,setTempArray] = useState([])
  const [tempDup, setTempDup] = useState([]);
  const [selectedItems, setSelectedItems] = useState(() => {
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    selectedProducts = selectedProducts === null || selectedProducts === undefined ? {} : selectedProducts;
    if (selectedProducts.productsData !== undefined || selectedProducts.productData !== null) {
      return selectedProducts.productsData;
    } else {
      return [];
    }
  });
  const [search, setSearch] = useState("");
  const [cotton, setCotton] = useState([]);
  const [paper, setPaper] = useState([]);
  const [modalFlag, setModalFlag] = useState(false);
  const [modalItem, setModalItem] = useState();
  const [summaryObj, setSummaryObj] = useState({ items : 0, count : 0, discount : 0, total : 0 });
  const [comboDiscountData, setComboDiscountData] = useState([]);
  const [gifFlag, setGifFlag] = useState(true);
  const [multiBuyData, setMultiBuyData] = useState([]);
  const [routeFlag, setRouteFlag] = useState(false);
  const [name, setName] = useState("");
  const [paperFlag, setPaperFlag] = useState(true);
  const [cottonFlag, setCottonFlag] = useState(true);
  const [timeoutFlag, setTimeoutFlag] = useState(false);
  const [time, setTime] = useState(300);

  useEffect(() => {
    setTimeout(() => {
      setGifFlag(false);
    }, 2000)
  }, []);
  

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(time - 1);
    }, 1000);

    if (time === 30) {
      setTimeoutFlag(true);
    }

    if (time === 0) {
      let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
      selectedProducts = selectedProducts === null ? {} : selectedProducts;
      selectedProducts.productsData = [];
      localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
      navigate("/customer-login");
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  });

  useEffect(() => {
    setLoading({ status : true, message: "NonRfid products are loading..." });
    let tempName = localStorage.getItem("surname");
    let paperflag = localStorage.getItem("paperBag");
    let cottonflag = localStorage.getItem("cottonBag");
    setPaperFlag(paperflag);
    setCottonFlag(cottonflag);
    setName(tempName);
    (async () => {
      let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
      selectedProducts = selectedProducts === null ? {} : selectedProducts;
      selectedProducts.productsData = selectedItems;
      localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
      const comboDiscount = await db.comboData.toArray();
      const multiBuy = await db.multiBuyData.toArray();
      setComboDiscountData(comboDiscount);
      setMultiBuyData(multiBuy);
    })();
    setLoading({ status : false });
    setTempArray(suggestData.NonRfid);
    setCotton(suggestData.Cotton);
    setPaper(suggestData.Paper);
    setTempDup(suggestData.NonRfid);
  }, []);

  useEffect(() => {
    let items = 0;
    let count = 0;
    let discount = 0;
    let total = 0;
    let arr = selectedItems?.length > 0 ? [...selectedItems] : [];
    arr.map((item, ind) => {
      items = items + ind + 1;
      count = count + item.count;
      discount = discount+ (item.sale_price *item.percentage)/100;
      total = total + (item.sale_price);
      if(item.id === "2365103"){
        item.imageUrl = bag1;
      }else if(item.id === "2991796"){
        item.imageUrl = bag2
      }
    });
    setSummaryObj({ items : items, count : count, discount : discount, total : total });
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    selectedProducts = selectedProducts === null ? {} : selectedProducts;
    selectedProducts.productsData = arr;
    localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
  }, [selectedItems]);

  const handleSubmit = () => {
    setLoading({ status: true, message: "" });
    const token = localStorage.getItem("crm_token");
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    const tempData = JSON.parse(localStorage.getItem("customerData"))
      Axios({
        url: `${URL.MAIN_URL}/customerLogin`,
        method: "POST",
        data:{
          "card_number": "",
          "email": `${tempData?.email}`,
          "mobile": `${tempData?.phoneNo}`
          },
        headers: {
          "Content-Type": "application/json",
          "Connection": "keep-alive",
          'x-api-key':"609ed4ce-1869-4059-a6eb-3c5e40896b01",
          Authorization: `EMPLOYEE ${token}`,
        },
        params:{
          "card_number": "",
          "email": `${tempData?.email}`,
          "mobile": `${tempData?.phoneNo}`
        }
      }).then(async(response)=>{
        if(response.status === 200){
          setLoading({ status: false, message: "" });
          selectedProducts = selectedProducts === null ? {} : selectedProducts;
          selectedProducts.productsData = [];
          localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
          let customerData = {phoneNo: tempData?.phoneNo,email:tempData?.email}
          if (response.data.customers === null) {
            localStorage.setItem('customerData', JSON.stringify(customerData));
            let currentDate = moment(new Date()).format("YYYY-MM-DD");
            let currentDateTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
            selectedProducts.customerData = response.data;
            localStorage.setItem('surname', response.data.customers[0].surname);
            localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
            localStorage.setItem('customerData', JSON.stringify(customerData));
            localStorage.setItem('orderDate', currentDate);
            localStorage.setItem('salebegintime', currentDateTime);
          }
        }else{
          setLoading({ status: false, message: "" });
        }
      });
    navigate('/payment-selection');
  }

  const padleft = (nr, n, str)=> {
    return Array(n - String(nr).length + 1).join(str || '0') + nr;
  }

  const decTobin =(dec)=>{
    return (dec >>> 0).toString(2)
  }

  const convertEpcToItemCode = async (inputString) => {
    setTime(300);
    inputString = inputString.toString();
    let alreadyScanned = "N";
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    selectedProducts = selectedProducts === null ? {} : selectedProducts;
    if (selectedProducts.productsData !== undefined || selectedProducts.productData !== null) {
      selectedProducts?.productsData?.map(item => {
        for (let ind = 1; ind < item?.count+1; ind++) {
          if (item[`epcCode${ind}`] === inputString) {
            alreadyScanned = "Y";
            message.info("Product Already Scanned");
          } 
        };
      })
    };
    if (alreadyScanned !== "Y") {
      setModalFlag(true);
      let binaryString = new String();
      for (let i = 0; i < inputString.length; i++) {
        const hexadigit = inputString.substring(i, i + 1);
        const decimal = parseInt(hexadigit, 16);
        const binary = decTobin(decimal);
        const fourDigitBinary = padleft(binary, 4, '0');
        binaryString = binaryString.concat(fourDigitBinary);
      };
      binaryString = binaryString.toString();
      const binaryPartition = binaryString.substring(11, 14);
      const partition = parseInt(binaryPartition, 2);
      const numberOfBitsOfItemReference = partition * 4;
      const binaryCompanyPrefix = binaryString.substring(14, 58 - numberOfBitsOfItemReference);
      const binaryItemReference = binaryString.substring(58 - numberOfBitsOfItemReference, 58);
      const binarySerialNumber = binaryString.substring(58, 96);
      const companyPrefix = padleft(parseInt(binaryCompanyPrefix, 2).toString(), 13 - partition, '0');
      const itemReference = padleft(parseInt(binaryItemReference, 2).toString(), partition, '0');
      let serialNumber = parseInt(binarySerialNumber, 2).toString();
      serialNumber = serialNumber.toString().padStart(12, '0');
      const eanWithoutCheckDigit = companyPrefix + itemReference;
      let j = eanWithoutCheckDigit.length - 1, oddSum = 0, evenSum = 0;
      while (j > 0) {
        oddSum = oddSum + parseInt(eanWithoutCheckDigit.charAt(j).toString());
        evenSum = evenSum + parseInt(eanWithoutCheckDigit.charAt(j - 1).toString());
        j = j - 2;
      }
      const totalSum = oddSum * 3 + evenSum;
      const nearestTenMultiple = Math.ceil(parseFloat((totalSum / 10).toString())) * 10;

      const checkDigit = parseInt((nearestTenMultiple - totalSum).toString());
      const finalEanNumber = eanWithoutCheckDigit.startsWith('0') ?
        eanWithoutCheckDigit.substring(1, eanWithoutCheckDigit.length) + checkDigit
        : eanWithoutCheckDigit + checkDigit;

      await db.productData.toArray().then(response => {
        let arr = selectedItems?.length > 0 ? [...selectedItems] : [];
        response?.map(item => {
          if (item.ean === finalEanNumber) {
            setModalFlag(true)
            rfidDiscountCalculation(finalEanNumber)
          };
        });
        selectedProducts.productsData = arr;
        localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
        setTimeout(() => {
          setModalFlag(false);
          setSelectedItems(arr);
        }, 500);
      });
    }
  };

  const consvertUpcToItemCode = async (upc) => {
    setTime(300);
    setModalFlag(true);
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    selectedProducts = selectedProducts === null ? {} : selectedProducts;
    await db.productData.toArray().then(response => {
      let arr = selectedItems?.length > 0 ? [...selectedItems] : [];
      response?.map(item => {
        if (item.ean === upc) {
          rfidDiscountCalculation(upc)
        };
      });
      selectedProducts.productsData = arr;
      localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
      setTimeout(() => {
        setModalFlag(false);
        setSelectedItems(arr);
      }, 500);
    });
  };

  const onScannerInput = (data) => {
    setTime(300);
    if(data.length === 24){
      convertEpcToItemCode(data.toString())
    } else {
      consvertUpcToItemCode(data);
    }
  };

  const handleSelectedItems = (item) => () => {
      setTime(300);
      setModalFlag(true);
      discountCalculation(item.id)
  };

  const handleRemoveItems = (ean) => () => {
      setTime(300);
      let array = selectedItems?.length > 0 ? [...selectedItems] : [];
      let arr = array.filter(item => item.orderId !== ean);
      let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
      let popArr = selectedProducts?.productsData?.filter(item => item.orderId !== ean);
      selectedProducts.productsData = popArr;
      comboDiscountData.map((item)=>{
        let newArray =[]
        let index =[]
          item.ComboFamily.map((ele,ind)=>{
            let array =[]
            ele.comboProducts.map(x=>{   
              arr.map((e)=>{
                if(x.combProduct === e.id){
                  x.sale_price = e.sale_price
                  x.count = e.count
                  e.percentage = ele.percentage
                  index.push(ind)
                  array.push({
                    key:ind,
                    value:e
                  })
                }
              })
            })
            if(array.length>0){
              newArray.push(array)
            }
          });
  
          let arrayLenght = 0;
          newArray.map((et)=>{
            if(arrayLenght <et.length){
              arrayLenght = et.length
            }
          });
  
          let mainArray = [];
          for (let i = 0; i < arrayLenght; i++) {
            let arr1 = []
            newArray.map((item, ind) => {
              if (i < item.length) {
                let orderId = uuid().replace(/-/g, "").toUpperCase();
                item[i].orderId = orderId
               return arr1.push(item[i])
              }
            });
  
            let dummy1 = new Set(arr1)
            let dummy = [...dummy1]
            if(item.ComboFamily.length === dummy.length){
              let tempArray = [...arr1]
              tempArray.filter((item,
                index) => arr.indexOf(item) === index)
              tempArray.map(ele=>{
                ele.value.orderId = ele.orderId
              })
            }
            mainArray.push(...arr1)
          }
          const newData = [...mainArray]
          newData.map((e)=>{
            if(e.orderId === e.value.orderId){
              e.value.discountApply = "Y"
              e.value.discount = (e.value.sale_price *e.value.percentage*e.value.count)/100
            }else{
              e.value.percentage = 0
            }
          }) 
      });
  
      let total = 0
      arr.map((item, ind) => {
        total = total + (item.sale_price);
      });
      let currentDate = moment(new Date()).format("YYYY-MM-DD")
  
      multiBuyData.map((item)=>{
        if(item.StartingDate <= currentDate &&(currentDate<=item.EndingDate || item.EndingDate === "")){
          if(total >= item.discountTotalReceipt){
            item.OfferProduct.map((ele)=>{
              arr.map((e)=>{
                if(ele.productId === e.id){
                  let orderId = uuid().replace(/-/g, "").toUpperCase();
                  e.orderId = orderId
                  e.discount = (e.sale_price *item.totalPercentageDiscount*e.count)/100
                  e.percentage = item.totalPercentageDiscount
                  e.discountApply = "Y"
                }
              })
            })
          }
        }
      });
  
      arr.map((item)=>{
        if(item.discountApply !== "Y"){
          let orderId = uuid().replace(/-/g, "").toUpperCase();
          item.orderId = orderId
          item.percentage = 0
        }
        item.count = 1;
      })
  
      localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
      setSelectedItems(arr);
  };

  const rfidDiscountCalculation = (search)=>{
    let arr = selectedItems?.length > 0 ? [...selectedItems] : [];
    db.productData
        .where("ean")
        .equals(search)
        .toArray()
        .then((response)=>{
            setModalItem(response[0]);
            arr.unshift(response[0]);
            comboDiscountData.map((item)=>{
              let newArray =[]
              let index =[]
                item.ComboFamily.map((ele,ind)=>{
                  let array =[]
                  ele.comboProducts.map(x=>{   
                    arr.map((e)=>{
                      if(x.combProduct === e.id){
                        x.sale_price = e.sale_price
                        x.count = e.count
                        e.percentage = ele.percentage
                        index.push(ind)
                        array.push({
                          key:ind,
                          value:e
                        })
                      }
                    })
                  })
                  if(array.length>0){
                    newArray.push(array)
                  }
                });

                let arrayLenght = 0;
                newArray.map((et)=>{
                  if(arrayLenght <et.length){
                    arrayLenght = et.length
                  }
                });

                let mainArray = [];
                for (let i = 0; i < arrayLenght; i++) {
                  let arr1 = []
                  newArray.map((item, ind) => {
                    if (i < item.length) {
                      let orderId = uuid().replace(/-/g, "").toUpperCase();
                      item[i].orderId = orderId
                     return arr1.push(item[i])
                    }
                  });

                  let dummy1 = new Set(arr1)
                  let dummy = [...dummy1]
                  if(item.ComboFamily.length === dummy.length){
                    let tempArray = [...arr1]
                    tempArray.filter((item,
                      index) => arr.indexOf(item) === index)
                    tempArray.map(ele=>{
                      ele.value.orderId = ele.orderId
                    })
                  }
                  mainArray.push(...arr1)
                }
                const newData = [...mainArray]
                newData.map((e)=>{
                  if(e.orderId === e.value.orderId){
                    e.discountName = "Scov2";
                    e.value.discountApply = "Y"
                    e.value.discount = (e.value.sale_price *e.value.percentage*e.value.count)/100
                  }else{
                    e.value.percentage = 0
                  }
                })
            });

            let total = 0
            arr.map((item, ind) => {
              total = total + (item.sale_price);
            });

            let currentDate = moment(new Date()).format("YYYY-MM-DD")
          
            multiBuyData.map((item)=>{
              if(item.StartingDate <= currentDate &&(currentDate<=item.EndingDate || item.EndingDate === "")){
                if(total >= item.discountTotalReceipt){
                  item.OfferProduct.map((ele)=>{
                    arr.map((e)=>{
                      if(ele.productId === e.id){
                        let orderId = uuid().replace(/-/g, "").toUpperCase();
                        e.orderId = orderId
                        e.discount = (e.sale_price *item.totalPercentageDiscount*e.count)/100
                        e.percentage = item.totalPercentageDiscount
                        e.discountName = "Multi Buy";
                        e.discountApply = "Y"
                      }
                    })
                  })
                }
              }
            });

            arr.map((item)=>{
              if(item.discountApply !== "Y"){
                let orderId = uuid().replace(/-/g, "").toUpperCase();
                item.orderId = orderId
                item.percentage = 0
              }
              item.count = 1;
            })
            setTimeout(() => {
              setModalFlag(false);
              setSelectedItems(arr);
            }, 500);
        });
  }

  const discountCalculation = (search)=>{
    let arr = selectedItems?.length > 0 ? [...selectedItems] : [];
    db.productData
        .where("id")
        .equals(search)
        .toArray()
        .then((response)=>{
            setModalItem(response[0]);
            arr.unshift(response[0]);
            comboDiscountData.map((item)=>{
              let newArray =[]
              let index =[]
                item.ComboFamily.map((ele,ind)=>{
                  let array =[]
                  ele.comboProducts.map(x=>{   
                    arr.map((e)=>{
                      if(x.combProduct === e.id){
                        x.sale_price = e.sale_price
                        x.count = e.count
                        e.percentage = ele.percentage
                        index.push(ind)
                        array.push({
                          key:ind,
                          value:e
                        })
                      }
                    })
                  })
                  if(array.length>0){
                    newArray.push(array)
                  }
                });

                let arrayLenght = 0;
                newArray.map((et)=>{
                  if(arrayLenght <et.length){
                    arrayLenght = et.length
                  }
                });

                let mainArray = [];
                for (let i = 0; i < arrayLenght; i++) {
                  let arr1 = []
                  newArray.map((item, ind) => {
                    if (i < item.length) {
                      let orderId = uuid().replace(/-/g, "").toUpperCase();
                      item[i].orderId = orderId
                     return arr1.push(item[i])
                    }
                  });

                  let dummy1 = new Set(arr1)
                  let dummy = [...dummy1]
                  if(item.ComboFamily.length === dummy.length){
                    let tempArray = [...arr1]
                    tempArray.filter((item,
                      index) => arr.indexOf(item) === index)
                    tempArray.map(ele=>{
                      ele.value.orderId = ele.orderId
                    })
                  }
                  mainArray.push(...arr1)
                }
                const newData = [...mainArray]
                newData.map((e)=>{
                  if(e.orderId === e.value.orderId){
                    e.discountName = "Scov2";
                    e.value.discountApply = "Y"
                    e.value.discount = (e.value?.sale_price *e.value.percentage*e.value.count)/100
                  }else{
                    e.value.percentage = 0
                  }
                })
            });

            let total = 0
            arr.map((item, ind) => {
              total = total + (item.sale_price);
            });
            let currentDate = moment(new Date()).format("YYYY-MM-DD")
            multiBuyData.map((item)=>{
              if(item.StartingDate <= currentDate &&(currentDate<=item.EndingDate || item.EndingDate === "")){
                if(total >= item.discountTotalReceipt){
                  item.OfferProduct.map((ele)=>{
                    arr.map((e)=>{
                      if(ele.productId === e.id){
                        let orderId = uuid().replace(/-/g, "").toUpperCase();
                        e.orderId = orderId
                        e.discount = (e.sale_price *item.totalPercentageDiscount*e.count)/100
                        e.percentage = item.totalPercentageDiscount
                        e.discountName = "Multi Buy";
                        e.discountApply = "Y"
                      }
                    })
                  })
                }
              }
            });

            arr.map((item)=>{
              if(item?.discountApply !== "Y"){
                let orderId = uuid().replace(/-/g, "").toUpperCase();
                item.orderId = orderId
                item.percentage = 0
              }
              item.count = 1;
            })
            setTimeout(() => {
            setModalFlag(false);
              setSelectedItems(arr);
            }, 500);
        });
  }

  const handleSearchItems = () => {
    setTime(300);
    setModalFlag(true)
    discountCalculation(search)
    setSearch("");
  };

  const antIcon = <LoadingOutlined style={{ fontSize: "24px" }} spin />;

  return (
    <div>
      {selectedItems === undefined || selectedItems?.length === 0 ?
        <Modal open={gifFlag} type="dashed" footer={null} closable={false} width={300} >
          <img src={loadingGif} alt="" textAlign="center" width="100%" />
        </Modal> :
        ""
      }
      <ScannerComponent onScan={onScannerInput} />
      <div className="header-block">
      <Row>
          <Col span={1} >
          </Col>
          <Col span={6} style={{ alignSelf: "center",marginLeft:"-19px"}}>
            <p className="user-name">Hey {name}</p>
          </Col>
          <Col span={9} style={{position:"relative",left:"2.6%"}}>
            <Input
              placeholder="Please enter item code"
              onChange={(e) => setSearch(e.target.value)}
              onPressEnter={handleSearchItems}
              value={search}
              prefix={<SearchOutlined onClick={handleSearchItems} />}
              className="itemCode-input"
              style={{boxShadow:"2px 2px 4px -3px black",width:"80%",height:"100%"}}
            />
          </Col>
          <Col span={8} className="user-details">
          <img src={Logo} alt="" width={100} onDoubleClick={() => { setRouteFlag(true); setTime(300); }} />
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Steps current={1} size="small">
            <Step
              title="Register"
              description={<Badge status="success" text="Completed" />}
            />
            <Step
              title="Scan"
              description={<Badge status="success" text="In Progress" />}
            />
            <Step title="Payment" />
            <Step title="Feedback" />
          </Steps>
        </Row>
      </div>
      <div className="info-block">
        <p className="info-text">
          Please drop your products one by one or enter their item code & For
          Deleting a Product, you need to tap on the item code and it will show
          you the delete option
        </p>
      </div>
      <div className="items-block">
        <Row>
          <Col span={18}>
          <Scrollbars autoHide className="prodCardsScroll">
          <Row style={{marginBottom: 5}}>
            {selectedItems?.length > 0 ?
              (selectedItems?.map(item => {
                return (
                    <Col span={window.innerWidth === 1024 && window.innerHeight === 768 ? 4 : 3} style={{paddingRight: 8}}>
                    <img src={CloseIcon} alt="" className="close-icon" style={{ cursor: "pointer" }} onClick={handleRemoveItems(item.orderId)}/>
                      <Card bodyStyle={{padding: 10}} className="suggest-item-card-home" style={{height:"170px"}}>
                        <div className="suggest-img-block">
                          <img src={item.imageUrl === "UNAVAILABLE" ? noImage : item.imageUrl} alt="" width={50} height={50} />
                        </div>
                        <Row style={{marginTop: 10}}> 
                          <Col span={18}>
                          <p className="suggest-item-home" title={item.name}>{window.innerWidth <= 1024 && window.innerHeight <= 768 ? item.name.length < 16 ? item.name : item.name.slice(0, 16).concat("...") :  item.name.length < 20 ? item.name : item.name.slice(0, 16).concat("...")}</p>
                          </Col>
                          <Col span={6}>
                            <p className="suggest-item-price-home" style={{backgroundImage:item.percentage>0 ? "repeating-linear-gradient(165deg,transparent,transparent 42%,rgba(26,138,230,.973),transparent 52%,transparent)" : "",marginTop:"-4px"}}>{item.sale_price}</p>
                            <p className="suggest-item-price-home" >{item.percentage>0 ? (item.sale_price-(item.sale_price*item.percentage/100)).toFixed(2) : ""}</p>
                          </Col>
                        </Row>
                        <Row> 
                          <Col span={15}>
                            <p className="item-code">{item.id}</p>
                          </Col>
                          <Col span={9}>
                            <div className="prod-inc">
                             {item.count}
                              </div>
                          </Col>
                        </Row>
                        <p className="prod-discount-home">
                          <img src={OfferIcon} alt="" /> Discount: {item.percentage} off
                        </p>
                      </Card>
                    </Col>
                )
              })) :
              (
                <>
                    <Col span={24}>
                      <img src={cartGif} alt="" width="40%" height="220vh" />
                    </Col>
                    <Col span={24}>
                      <p style={{ fontWeight: 600 }}>No items selected!</p>
                    </Col>
                    <Col span={24}>
                      <p style={{ fontSize: "10px", color: "gray", marginTop: "-10px" }}>Start adding the products to the cart to proceed for checkout process</p>
                    </Col>
                </>
              )
            }
          </Row>
        </Scrollbars>
          </Col>
          <Col span={6} style={{paddingLeft: 20}}>
          <Card bodyStyle={{padding: 15}} className="totalCard-home">
          <p className="current-summary">Current Summary</p>
          <div className="subtotal-details-div-home">
          <Row>
              <Col span={12} style={{textAlign: "left"}}>
                <p className="subtotal">Items:</p>
              </Col>
              <Col span={12}>
                <p className="subtotal-price">{summaryObj.count}</p>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{textAlign: "left"}}>
                <p className="subtotal">Subtotal:</p>
              </Col>
              <Col span={12}>
                <p className="subtotal-price">₹ {summaryObj.total.toFixed(2)}</p>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{textAlign: "left"}}>
                <p className="discount-label">Discount:</p>
              </Col>
              <Col span={12}>
                <p className="discount-price-home">₹ {summaryObj.discount.toFixed(2)}</p>
              </Col>
            </Row>
          </div>
          <div className="total-details-div-home">
            <Row>
              <Col span={12} style={{textAlign: "left"}}>
                <p className="total">Total:</p>
              </Col>
              <Col span={12}>
                <p className="total-price">₹ {(summaryObj.total-summaryObj.discount).toFixed(2)}</p>
              </Col>
            </Row>
          </div>
          <Button htmlType="submit" className="continue-btn-cart-home" disabled={selectedItems?.length > 0 ? false : true} onClick={handleSubmit}>
            Continue
          </Button>
          </Card>
          </Col>
        </Row>
      </div>
      <Spin indicator={antIcon} spinning={loading.status} tip={loading.message}>
      <div className="suggestedItems-block">
        <p className="suggested-items">Suggested Items <img src={RightArrow} alt="" style={{width: "20px", verticalAlign: "text-bottom"}}/> </p>
        <Row style={{display:"flex",alignItems:"center"}}> 
          <Col span={1} style={{height:"100%",}}>
          </Col>
          <Col span={22}>
          <div style={{overflowX:"scroll",display:"flex"}}>
            {cottonFlag === "true" ?
              cotton?.map(item => {
                if (item.id === "2365103") {
                  return (
                    <div >
                      <Card bodyStyle={{padding: 10}} className="suggest-item-card" style={{width:"14vw",marginRight:"1vw",height:"100%"}}>
                          <div className="suggest-img-block">
                              <img src={bag1} alt="" style={{ width: 50, height: 50 }} />
                          </div>
                          <Row style={{marginTop: 10}}> 
                            <Col span={18} >
                            <p className="suggest-item" style={{height:"4vh"}} title={item.name}>{item.name}</p>
                            </Col>
                            <Col span={6}>
                                <p className="suggest-item-price">₹ {item.sale_price}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <Button className="suggest-addBtn" onClick={handleSelectedItems(item)}>Add +</Button>
                            </Col>
                          </Row>
                      </Card>
                    </div>
                  )
                }}) :
                ""
            }
            {paperFlag === "true" ?
              paper?.map(item => {
                if (item.id === "2991796") {
                  return (
                    <div>
                      <Card bodyStyle={{padding: 10}} className="suggest-item-card" style={{width:"14vw",marginRight:"1vw",height:"100%"}}>
                          <div className="suggest-img-block">
                              <img src={bag2} alt="" style={{ width: 50, height: 50 }}/>
                          </div>
                          <Row style={{marginTop: 10}}> 
                            <Col span={18}>
                                <p className="suggest-item" style={{height:"4vh"}} title={item.name}>{item.name}</p>
                            </Col>
                            <Col span={6}>
                                <p className="suggest-item-price">₹ {item.sale_price}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <Button className="suggest-addBtn" onClick={handleSelectedItems(item)}>Add +</Button>
                            </Col>
                          </Row>
                      </Card>
                    </div>
                  )
                }
              }) :
              ""
            }
          {tempDup.map((item)=>{
             return(
                <div>
                  <Card bodyStyle={{padding: 10}} className="suggest-item-card" style={{width:"14vw",marginRight:"1vw",height:"100%"}}>
                    <div className="suggest-img-block">
                      <img src={item.imageUrl} alt="" style={{ width: 50, height: 50 }} />
                    </div>
                    <Row style={{marginTop: 10}}> 
                      <Col span={18}>
                      <p className="suggest-item" style={{height:"4vh"}} title={item.name}>{window.innerWidth <= 1024 && window.innerHeight <= 768 ? item.name.length < 20 ? item.name : item.name.slice(0, 20).concat("...") :  item.name.length < 20 ? item.name : item.name.slice(0, 18).concat("...")}</p>
                      </Col>
                      <Col span={6}>
                        <p className="suggest-item-price">₹ {item.sale_price}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                          <Button className="suggest-addBtn" onClick={handleSelectedItems(item)}>Add +</Button>
                      </Col>
                    </Row>
                  </Card>
                </div>)
            })
          }
            
          </div>
          </Col>
          <Col span={1} style={{height:"100%"}}>
          </Col>
        </Row>
      </div>
      </Spin>
      <Modal open={modalFlag} type="dashed" footer={null} closable={false} centered={true} width={200}>
        <img src={modalItem?.imageUrl} alt="" width={100} />
        <p>{modalItem?.name}</p>
      </Modal>
      <Modal centered open={routeFlag} closable={false} footer={null} width={300}>
        <p style={{ fontSize: "15px", textAlign: "center" }}>Are you sure you want to cancel the transaction ?</p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button style={{ borderRadius: "4px" }} onClick={() => { setRouteFlag(false); setTime(300); }}>No</Button>
              <Button style={{ backgroundColor: "#0083c6", borderRadius: "4px",  color: "white" }} onClick={() => { 
                let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
                selectedProducts = selectedProducts === null ? {} : selectedProducts;
                selectedProducts.productsData = [];
                localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
                navigate("/customer-login");
              }}>Yes</Button>
            </div>
      </Modal>
      <Modal centered open={timeoutFlag} closable={false} footer={null} width={300}>
        <p style={{ fontSize: "15px", textAlign: "center" }}>Transaction Timeout in</p>
        <p style={{ fontSize: "15px", textAlign: "center", color: "red" }}>00:00:{time}</p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button style={{ borderRadius: "4px" }} onClick={() => { setTimeoutFlag(false); setTime(300); }}>Cancel</Button>
            </div>
      </Modal>
    </div>
  );
};

export default Home;
