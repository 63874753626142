import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Avatar, Steps, Badge, Card, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import Logo from "../../assets/SCO_asset/home/decathlon-logo.png";
import RightArrowWhite from "../../assets/SCO_asset/icons/right-arrow-white.svg";
import Tick from "../../assets/SCO_asset/ebill-buttons/tick.svg";
import _ from "lodash";

const { Step } = Steps;

const FeedbackStepOne = () => {
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  const [selectedValue, setSelectedValue] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    let tempName = localStorage.getItem("surname");
    setName(tempName);
  }, []);

  const onChange = (value) => {
    console.log("onChange:", current);
    setCurrent(value);
  };

  const feedbackTextList = [
    {
      title: "Checkout Experience",
    },
    {
      title: "Staff Interaction",
    },
    {
      title: "Product Price",
    },
    {
      title: "Product Range",
    },
    {
      title: "Stock Availability",
    },
    {
      title: "Trial Room Experience",
    },
    {
      title: "Others",
    },
  ];

  const handleBillModeSelection = (value, cardIndex) => {
    const selectedBillModeIndex = _.findIndex(feedbackTextList, [
      "title",
      value,
    ]);
    console.log("selectedBillModeIndex", selectedBillModeIndex, cardIndex);
    if (selectedBillModeIndex === cardIndex) {
      setSelectedValue(value);
    }
  };

  const handleFeedback = () => {
    if(selectedValue !== "") {
      navigate('/feedback-steptwo');
    }
  }

  return (
    <div>
      <div className="header-block">
        <Row>
        <Col span={1} >
          <Avatar
              style={{
                backgroundColor: "#0079ba",
                height: 30,
                width: 30
              }}
              icon={<UserOutlined />}
            />
          </Col>
          <Col span={6} style={{ alignSelf: "center",position:"relative",left:"-2%"}}>
            <p className="user-name">Hey {name}</p>
          </Col>
          <Col span={9} style={{position:"relative",left:"2.6%"}}>
          </Col>
          <Col span={8} className="user-details">
          <img src={Logo} alt="" width={100} />
          </Col>
        </Row>
        <Row style={{ marginTop: "1.5rem" }}>
          <Steps current={current} onChange={onChange} size="small">
            <Step
              title="Register"
              description={<Badge status="success" text="Completed" />}
            />
            <Step
              title="Scan"
              description={<Badge status="success" text="In Progress" />}
            />
            <Step title="Payment" />
            <Step title="Feedback" />
          </Steps>
        </Row>
      </div>
      <div className="info-block">
        <p className="info-text">
          Please help us with your valusable feedback this will help us to be
          better everytime
        </p>
      </div>
      <div className="payment-method-block">
        <p className="what-would">
          What would you like us to improve on with regards to your experience
          with us today?
        </p>
        <Row style={{ margin: "auto" }}>
          <Col span={4} />
          <Col span={18}>
            <Row>
              {feedbackTextList.map((item, index) => (
                <Col
                  span={8}
                  style={{
                    paddingRight: 20,
                    paddingBottom: 30,
                    cursor: "pointer",
                  }}
                  onClick={() => handleBillModeSelection(item.title, index)}
                >
                  <img
                    src={Tick}
                    alt=""
                    className="tick-icon-feedback"
                    style={{
                      visibility:
                        selectedValue === item.title ? "visible" : "hidden",
                    }}
                  />
                  <Card
                    bodyStyle={{ padding: 7 }}
                    className={
                      selectedValue === item.title
                        ? "feedback-card-active"
                        : "feedback-card"
                    }
                  >
                    <p className="bill-mode-text">{item.title}</p>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
          <Col span={4} />
        </Row>
        <Row>
          <Col span={24}>
            <Button className="proceed-nextBtn" onClick={handleFeedback}>
              Proceed &nbsp;{" "}
              <img src={RightArrowWhite} alt="" width="16px" />
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FeedbackStepOne;
