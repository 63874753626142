import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, message, Modal, Button, Switch } from "antd";
import Logo1 from "../../assets/SCO_asset/home/decathlon-logo.png";
import CashUp from "../../assets/SCO_asset/admiPageGif/CashUp.gif";
import IncrementalRefresh from "../../assets/SCO_asset/admiPageGif/IncrementalRefresh.gif";
import MasterRefresh from "../../assets/SCO_asset/admiPageGif/MasterRefresh.gif";
import Axios from "axios";
import db from "../../database";
import URL from "../../app/server.json";
import "./cashup.css";

const Admin = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isIncrementModal, setIsIncrementModal] = useState(false);
  const [cashupDetails,setCashupDetails] = useState([]);
  const [isEbill,setIsEbill] = useState(true);
  const [isPaperBag,setIsPaperBag] = useState(true);
  const [isCottonBag,setIsCottonBag] = useState(true);
  const [tillData,setTillData] = useState([]);
  const [isPrint,setIsPrint] = useState(true);
  const [istempPrint,setIsTempPrint] = useState(false);
  const [cashierData, setCashierData] = useState();
  const [orderData,setOrderData] = useState([]);
  const [isFlag,setIsFlag] = useState(false);
  const [windowFlag,setWindowFlag] = useState(false);
  const [time,setTime] = useState(10);

let customerData = localStorage.getItem("selectedProducts");
let cashier = JSON.parse(localStorage.getItem('cashierData'));
let basicAuth = `${cashier?.Username}:${cashier?.Password}`


useEffect(()=>{
 if( window.innerWidth <= 1024 && window.innerHeight <= 768){
  setWindowFlag(true)
 }else{
  setWindowFlag(false)
 }
 let timer = time + 1;
 setTime(timer);
},[time])

useEffect(() => {
  const timer = setInterval(() => {
    setTime(time - 1);
  }, 1);

  if (time === 0) {
    clearInterval(timer);
  }

  return () => {
    clearInterval(timer);
  };
});

  useEffect(() => {
    (async () => {
        localStorage.setItem("ebill",isEbill);
        localStorage.setItem("paperBag",isPaperBag);
        localStorage.setItem("cottonBag",isCottonBag);
        setCashierData(cashier);
       let tillData = await db.tillData.toArray();
       let data = await db.syncData.toArray();
       let newData = [];
       console.log(data)
       data.map((item)=>{
        if(item !== undefined){
        newData.push(item[0])
        }
       })
       console.log(newData)
       setOrderData(newData);
       setTillData(tillData);
      })();
  }, [isFlag])

  let terminalNumber = localStorage.getItem("terminalNumber")
  let token = localStorage.getItem("token");

  const showModal = (props) => {
      if(props === "0"){
        setIsModalOpen(true);
      }else {
        setIsIncrementModal(true)
      }
  };

  const handleOk = (props) => {
      if(props === "0"){
        getMasterRefreshData()
      }else{
        getInrementalData()
      }
    setIsModalOpen(false);
  };

  const handleCancel = (e) => {
    setIsModalOpen(false);
    setIsIncrementModal(false);
  };

  const getMasterRefreshData=()=>{
    localStorage.setItem("isMasterRefresh",true)
    navigate("/loading")
  }

  const getInrementalData = ()=>{
    localStorage.setItem("isIncremenatalRefresh",true)
    navigate("/loading")
  }

  const getCashupDetails = async() => {
        return new Promise(async (cashupDataSuccess, cashupDataFailure) => {
          try {
            const response = await Axios({
              url: `${URL.MAIN_URL}/cashup`,
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Basic ${window.btoa(basicAuth)},${token}`,
              },
              params: {
                remainingUrl : `/ws/com.promantia.decathlon.custom_selfcheckout.cashupdetails?terminalname=${terminalNumber}`
              }
            })
            const data  = [response.data];
            if(response.status === 200 && data.length > 0){
              setCashupDetails(data)
              setIsTempPrint(true)
              cashupDataSuccess(data);
            }else{
              cashupDataSuccess();
            }
          } catch (error) {
            message.error("Error while fetching cashup");
            console.log("SCO tillData: ", " Sync Failed");
            cashupDataFailure(error);
          }
        });
  }

  window.onafterprint = (event) => {
    navigate("/admin-login");
    setIsTempPrint(false);
    setIsPrint(true);
  };

  const getPrint = async() => {
        return new Promise(async (cashupDataSuccess, cashupDataFailure) => {
          try {
            const response = await Axios({
              url: `${URL.MAIN_URL}/closecashup`,
              method: "POST",
              data:{"amountToKeep":"0"},
              headers: {
                "Content-Type": "application/json",
                Authorization: `Basic ${window.btoa(basicAuth)},${token}`,
              },
              params: {
                "amountToKeep":"0",
                remainingUrl : `/ws/com.promantia.decathlon.custom_selfcheckout.closecashup?terminalname=${terminalNumber}`
              }
            })
            const data  = [response.data];
            if(response.status === 200 && data.length > 0){
            setIsPrint(false)
            setIsTempPrint(false);
            setTimeout(()=>{
                window.print();
            },300)
              cashupDataSuccess(data);
            }else{
              cashupDataSuccess();
            }
          } catch (error) {
            console.log("SCO tillData: ", " Sync Failed");
            cashupDataFailure(error);
          }
        });
  }

  const onChangeEbill =(checked)=>{
    setIsEbill(checked)
    localStorage.setItem("ebill",checked);
  }

  const onChangePagerBag =(checked)=>{
    setIsPaperBag(checked)
    localStorage.setItem("paperBag",checked);
  }
  const onChangeCottonBag =(checked)=>{
    setIsCottonBag(checked)
    localStorage.setItem("cottonBag",checked);
  }


  const getSyncData = async() => {
    const status = navigator.onLine ? "online" : "offline";
    return new Promise(async (syncDataSuccess, syncDataFailure) => {
    let flag = "N"
      if(status === "online"){
        for(let i=0;i<orderData.length;i++){
          if(orderData[i] !== undefined){
            try{
              let syncData= await Axios({
                url: `${URL.MAIN_URL}/order`,
                method: "POST",
                data:{
                  "bills": [orderData[i]]
                },
                headers: {
                  "Content-Type": "application/json",
                  "Authorization" : `Basic ${window.btoa(basicAuth)},${token}`
                },
                params:{
                  remainingUrl : `/ws/com.promantia.decathlon.custom_selfcheckout.order`,
                  "bills": [orderData[i]]
                }
              })
              let responseData = syncData.status
              if(responseData === 200){
                flag = "Y";
              }else{
                flag = "N"
              }
            }catch(error){
              console.log("------------>error",error)
              syncDataFailure(error)
            }
          }
        };
          if(flag === "Y"){
            await db.syncData.clear();
            setIsFlag(true)
            syncDataSuccess();
          }else{
            syncDataFailure();
            message.error("Order is not processed");
          }
      }
    });
  };

  const CashupPrint=()=>{
    return(
      <div class="table-container">
  <div id="pdfTable" >
    <div class="table-header-container" style={istempPrint? {display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"} : {}}>
      <div class="table-header-item">
        <img src= {Logo1}/>
      </div>
      <div class="table-header-item">
       Cashup Report
      </div>
      <div class="table-header-item">
        CLOSED
       </div>
       <div class="table-header-item">
        Store Name:  {tillData[0]?.storeDetail?.storeName}
       </div>
       <div class="table-header-item">
        Terminal Number:  {terminalNumber}
       </div>
       <div class="table-header-item">
       User:  {JSON.parse(customerData).customerData.customers[0].surname}
       </div>
    </div>
    <table>
      <tr>
        <td>Net-Sales</td>
        <td>{cashupDetails[0]?.netSales}</td>
      </tr>
      {cashupDetails[0]?.cashTaxInfo?.map((item)=>{
        return (
        <tr>
        <td>{item.name}</td>
        <td>{item.account}</td>
        </tr>
        )
        })}
      <tr>
        <td>Gross Sales</td>
        <td>{cashupDetails[0]?.grossSales}</td>
      </tr>
    </table>
    <table>
      <tr>
        <td>Net Returns</td>
        <td>{cashupDetails[0]?.netReturns}</td>
      </tr>
      <tr>
        <td>Gross Returns</td>
        <td>{cashupDetails[0]?.grossReturns}</td>
      </tr>
    </table>
    <table>
      <tr>
        <td>TOTAL RETAIL TRANSACTIONS</td>
        <td>{cashupDetails[0]?.totalRetailTransactions}</td>
      </tr>
    </table>
    <h3>STARTING:</h3>
    <table>
      {cashupDetails[0]?.cashPaymentMethodInfo?.map((item,ind)=>{
      return (
      <tr>
      <td>Starting {item?.paymentMethod }</td>
      <td>{ item?.startingCash }</td>
      </tr>
          )
        }
      )}
    </table>
    <h3>WITHDRAWAL:</h3>
    <table>
    {cashupDetails[0]?.cashPaymentMethodInfo?.map((item,ind)=>{
      return (
      <tr>
      <td>Starting {item?.paymentMethod }</td>
      <td>{ item?.totalDrops }</td>
      </tr>
          )
        }
      )}
    </table>
    <h3>DEPOSIT:</h3>
    <table>
    {cashupDetails[0]?.cashPaymentMethodInfo?.map((item,ind)=>{
      return (
      <tr>
      <td>Starting {item?.paymentMethod }</td>
      <td>{ item?.totalSales }</td>
      </tr>
          )
        }
      )}
    </table>

    <h3>TOTAL TO KEEP:</h3>
    <table>
    {cashupDetails[0]?.cashPaymentMethodInfo?.map((item,ind)=>{
      return (
      <tr>
      <td>Starting {item?.paymentMethod }</td>
      <td>{ item?.amountToKeep }</td>
      </tr>
          )
        }
      )}
    </table>
    <h3>TOTAL TO DEPOSIT:</h3>
    <table>
    {cashupDetails[0]?.cashPaymentMethodInfo?.map((item,ind)=>{
      return (
      <tr>
      <td>Starting {item?.paymentMethod }</td>
      <td>{ item?.totalDeposits }</td>
      </tr>
          )
        }
      )};
    </table>
  </div>
</div>

    )
  }

  return (
    <div>
   {
   isPrint === true ?
   <>
         <div className="header-block">
        <Row>
            <Col span={1} >
          </Col>
          <Col span={6} style={{ alignSelf: "center",position:"relative",left:"-2%"}}>
            <p className="user-name">Hey {cashierData?.Username}</p>
          </Col>
          <Col span={9} style={{position:"relative",left:"2.6%"}}>
          </Col>
          <Col span={8} className="user-details">
          <img src={Logo1} alt="" width={100} />
          </Col>
        </Row>
      </div>
      <div style={{float:"right",position:"relative",right: windowFlag ? orderData.length>0 ? "4.8%" : "11%" : orderData.length>0 ? "4.8%" : "14.5%",marginTop:"-13px"}}>
    {orderData.length>0 ? <Button style={{ width: 110, marginTop: "0px" ,height:"40px",marginTop:"35px",marginRight:"20px"}} htmlType="submit" className="continue-btn" onClick={getSyncData}>Sync Data-{orderData.length}</Button> : null}
        <Button style={{ width: 110, marginTop: "0px" ,height:"40px",marginTop:"35px"}} htmlType="submit" className="continue-btn" onClick={()=>{navigate("/customer-login")}}>Home</Button>
          </div>
        <div style={{display:"flex",justifyContent:"center",marginTop:"18px",}}>
          <span style={{width:"277px",fontStyle:"normal",fontWeight:"600",fontSize:"34px",textAlign:"center",alignItems:"center",marginLeft:"78px"}}>Welcome Admin!</span>
             </div>
        <Row style={{marginTop:"35px"}}>
            <Col span={2}/>
              <Col span={5}>
                  <div onClick={()=>showModal("0")} style={{border:"1px solid rgba(196, 196, 196, 0.43)",width: windowFlag ? "180px" : "230px",height:"350px",borderRadius:"12px",boxShadow:"0px 3px 6px #00000029"} }>
                      <div style={{display:"flex",justifyContent:"center"}}>
                  <img src={MasterRefresh} alt="" style={{marginTop:"45px"}} width="90px" height="90px" />
                      </div>
                  <p style={{marginTop:"31px",textAlign:"center",fontWeight:"600",fontSize:"16px"}}>Master Refresh</p>
                  <p style={{margin:"0 10px",fontSize:"14px",fontWeight:"500",textAlign:"center",color:" rgba(0, 0, 0, 0.4)"}}>Master refresh will load all the products listed in Decathlon. This will take 5-10 minutes.</p>
                  </div>
                  </Col>
               <Col span={5}>
                  <div onClick={()=>showModal("1")} style={{border:"1px solid rgba(196, 196, 196, 0.43)",width:windowFlag ? "180px" : "230px",height:"350px",borderRadius:"12px",boxShadow:"0px 3px 6px #00000029"}}>
                  <div style={{display:"flex",justifyContent:"center"}}>
                  <img src={IncrementalRefresh} width="193px" height="193px" alt=""/>
                      </div>
                  <p style={{textAlign:"center",fontWeight:"600",fontSize:"16px",marginTop:"-28px"}}>Increamental Refresh</p>
                  <p style={{margin:"0 10px",fontSize:"14px",fontWeight:"500",textAlign:"center",color:" rgba(0, 0, 0, 0.4)"}}>Helps refresh the POS. Ideally done daily as a good practice.</p>
                  </div>
                  </Col>
              <Col span={5}>
                  <div onClick={getCashupDetails} style={{border:"1px solid rgba(196, 196, 196, 0.43)",width:windowFlag ? "180px" : "230px",height:"350px",borderRadius:"12px",boxShadow:"0px 3px 6px #00000029",overflow:"hidden"}}>
                  <div style={{display:"flex",justifyContent:"center"}}>
                  <img src={CashUp} width="204px" height="166px" alt="" style={{marginTop:"-21px",marginBottom:"19px"}} />
                      </div>
                  <p style={{textAlign:"center",fontWeight:"600",fontSize:"16px"}}>Cashup Details</p>
                  <p style={{margin:"0 10px",fontSize:"14px",fontWeight:"500",textAlign:"center",color:" rgba(0, 0, 0, 0.4)"}}>Captures all the transaction details done on this POS.</p>
                  </div>
                  </Col>
              <Col span={5} style={{marginRight:"20px",display:"flex",justifyContent:"space-between",flexDirection:"column"}}>
                  <div style={{border:"1px solid rgba(196, 196, 196, 0.43)",width:"280px",height:"105px",borderRadius:"12px",backgroundColor:"rgba(0, 131, 198, 0.05)"}}>
                      <Row style={{marginTop:"13px"}}>
                          <Col span={1} style={{marginLeft:"5px"}}></Col>
                          <Col span={18}>
                              <p style={{fontWeight:"600",fontSize:"16px"}}>E-bill</p>
                              <p style={{marginTop:"-5px"}}>Conseq uatur distin mollitia harum exercita uyght lab autem.</p>
                          </Col>
                          <Col span={4}><Switch onChange={onChangeEbill} style={{backgroundColor:isEbill ? "rgba(94, 197, 4, 1)" : "red"}} defaultChecked></Switch></Col>
                      </Row>
                  </div>
                  <div style={{border:"1px solid rgba(196, 196, 196, 0.43)",width:"280px",height:"105px",borderRadius:"12px",backgroundColor:"rgba(0, 131, 198, 0.05)"}}>
                  <Row style={{marginTop:"13px"}}>
                          <Col span={1} style={{marginLeft:"5px"}}></Col>
                          <Col span={18}>
                              <p style={{fontWeight:"600",fontSize:"16px"}}>Paper bags</p>
                              <p style={{marginTop:"-5px"}}>Conseq uatur distin mollitia harum exercita uyght lab autem.</p>
                          </Col>
                          <Col span={4}><Switch style={{backgroundColor:isPaperBag ? "rgba(94, 197, 4, 1)" : "red"}} onChange={onChangePagerBag} defaultChecked></Switch></Col>
                      </Row>
                  </div>
                  <div style={{border:"1px solid rgba(196, 196, 196, 0.43)",width:"280px",height:"105px",borderRadius:"12px",backgroundColor:"rgba(0, 131, 198, 0.05)"}}>
                  <Row style={{marginTop:"13px"}}>
                          <Col span={1} style={{marginLeft:"5px"}}></Col>
                          <Col span={18}>
                              <p style={{fontWeight:"600",fontSize:"16px"}}>Cotton bags</p>
                              <p style={{marginTop:"-5px"}}>Conseq uatur distin mollitia harum exercita uyght lab autem.</p>
                          </Col>
                          <Col span={4}><Switch style={{backgroundColor:isCottonBag ? "rgba(94, 197, 4, 1)" : "red"}} onChange={onChangeCottonBag} defaultChecked></Switch></Col>
                      </Row>
                  </div>
              </Col>
        </Row>
        <Row >
        <Col span={2}/>
              <Col span={5} style={{marginRight:"20px",marginLeft:"-10px"}}></Col>
               <Col span={5} style={{marginRight:"20px",marginLeft:"-10px",position:"relative",left:istempPrint ? "2%" : "13%"}}>
        <Button style={{ width: 170, marginTop: "0px" ,height:"40px",marginTop:"35px"}} htmlType="submit" className="continue-btn" onClick={()=>{navigate("/admin-login")}}>Logout</Button>
                 </Col>
              <Col span={5} style={{marginRight:"20px",marginLeft:"-10px",position:"relative",left:"1%"}}>
              {istempPrint ? <Button style={{ width: 170, marginTop: "0px" ,height:"40px",marginLeft:"20px",marginTop:"35px",display:cashupDetails.length>0 ? "" : "none"}} htmlType="submit" className="continue-btn" onClick={getPrint}>Post Print And Close</Button> : ""}
              </Col>
        </Row>
        {istempPrint ? <CashupPrint/> : "" }
   </>
   : <CashupPrint/>
   }
      <Modal visible={isModalOpen} onOk={()=>{handleOk("0")}} onCancel={handleCancel} height={240} width={400}>
          <Row>
              <Col span={2}/>
              <Col span={20} style={{textAlign:"center",fontWeight:"500",fontSize:"large"}}>
              <p style={{marginTop:"20px"}}>Warning Message: Master Refresh will take 15 Minutes to load.</p>
        <p>Are you sure you want to continue?</p>
              </Col>
              <Col span={2}/>
          </Row>
      </Modal>
      <Modal visible={isIncrementModal} onOk={()=>{handleOk("1")}} onCancel={()=>{handleCancel("1")}} height={240} width={400}>
          <Row>
              <Col span={2}/>
              <Col span={20} style={{textAlign:"center",fontWeight:"500",fontSize:"large"}}>
              <p style={{marginTop:"20px"}}>Are you sure you want to continue?</p>
              </Col>
              <Col span={2}/>
          </Row>
      </Modal>
    </div>
  );
};

export default Admin;
  
