import React,{useState,useEffect,useRef} from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Typography, Form, Input, Button,Modal,Spin } from "antd";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "antd/dist/antd.css";
import "../../antd.css";
import "../styles.css";
import SignupGif from "../../assets/SCO_asset/login-gif/signup-1.gif";
import Announcement from "../../assets/SCO_asset/login-gif/announcement.svg";
import Axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import db from "../../database";
import URL from "../../app/server.json";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

const { Title } = Typography;
const { Paragraph } = Typography;

const Signup = () => {
  const [form] = Form.useForm();
  const userName1 = useRef();
  const navigate = useNavigate();
  const [loading, setLoading] = useState({ status: false, message: "" });
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [terminalNumber,setTerminalNumber] = useState("");
  const [keyboard, setKeyboard] = useState(1);
  const [layoutUserName, setlayoutUserName] = useState("default");
  const [layoutPassword, setlayoutPassword] = useState("default");

  useEffect(() => {
    (async () => {
      localStorage.setItem("ebill",true);
      localStorage.setItem("paperBag",true);
      localStorage.setItem("cottonBag",true);
      const sessionId = localStorage.getItem("sessionId");
      if (sessionId !== "" &&  typeof(sessionId) === "string") {
        navigate("/");
      } else {
        setTimeout(() => {
          userName1.current.focus();
        }, 100);
      };
    })();
  }, []);

  const handleHome = () => {
    setLoading({ status: true, message: "" });
    const formData = form.getFieldsValue(true)
    Axios({
      url: `${URL.MAIN_URL}/signup`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Connection": "keep-alive",
      },
      params: {
        remainingUrl : `/ws/com.promantia.decathlon.custom_selfcheckout.logincheck?l=${userName}&p=${password}&terminalname=${terminalNumber}&sessionId=`
      }
    }).then(async(response)=>{
      if(response.data.status !== "FAILURE"){
        localStorage.setItem("sessionId",response.data.sessionId)
        localStorage.setItem("terminalNumber",terminalNumber)
        localStorage.setItem("token",response.data.token);
        localStorage.setItem("isMasterRefresh",false);
        localStorage.setItem("isIncremenatalRefresh",false);
        localStorage.setItem("cashierData", JSON.stringify(response.data));
        await db.cashierData.add(response.data);
        navigate("/loading");
      }else{
        setLoading({ status: false, message: "" });
        errorMessage("SCO Registration Failed", "Duplicate terminal");
        return null;
      }
    });
  }

  const errorMessage = (modalTitle, modalContent) => {
    Modal.error({
      title: modalTitle,
      content: modalContent,
    });
  };

  const onKeyPress = (button) => {
  if(keyboard === 1){
    if (button === "{bksp}") {
      setUserName(userName.slice(0, -1));
    };
    if (button === "{shift}" || button === "{lock}") handleShift();
    if(button !== "{shift}" && button !== "{lock}" && button !== "{bksp}" & button !== "{tab}"&& button !== "{enter}" & button !== "{space}"){
      let text = `${userName}${button}`
      setUserName(text.trim());
    }
  }else if(keyboard === 2){
    if (button === "{bksp}") {
      setPassword(password.slice(0, -1));
    };
    if (button === "{shift}" || button === "{lock}") handleShift();
    if(button !== "{shift}" && button !== "{lock}" && button !== "{bksp}" & button !== "{tab}"&& button !== "{enter}" & button !== "{space}"){
      let text = `${password}${button}`
      setPassword(text.trim());
    }
  }else if(keyboard === 3){
    if (button === "{bksp}") {
      setTerminalNumber(terminalNumber.slice(0, -1));
    };
    if (button === "{shift}" || button === "{lock}") handleShift();
    if(button !== "{shift}" && button !== "{lock}" && button !== "{bksp}" & button !== "{tab}"&& button !== "{enter}" & button !== "{space}"){
      let text = `${terminalNumber}${button}`
      setTerminalNumber(text.trim());
    }
  }
  };

  const handleShift = () => {
    const layout1 = layoutUserName;
    const layout2 = layoutPassword;
    setlayoutUserName(layout1 === "default" ? "shift" : "default");
    setlayoutPassword(layout2 === "default" ? "shift" : "default");
  };

  const handleUserName = (ev) => {
    setUserName(ev.target.value);
  };

  const handlePassword = (ev) => {
    setPassword(ev.target.value);
  };

  const handleTerminal = (ev) => {
    setTerminalNumber(ev.target.value);
  };

  const antIcon = <LoadingOutlined style={{ fontSize: "24px" }} spin />;

  return (
    <div>
      <Row>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
          <div className="adv-block">
            <div className="adv-block-div">
              <p className="shopping-with">
                Shopping with us has become more easier
              </p>
              <Carousel
                autoPlay={true}
                infiniteLoop={true}
                showArrows={false}
                showStatus={false}
                showIndicators={true}
                showThumbs={false}
              >
                <div>
                  <img src={SignupGif} alt="" className="adv-gif" />
                  <p className="get-all">
                    Get all the things you want in your cart and visit to the
                    self check out near the counter
                  </p>
                </div>
                <div>
                  <img src={SignupGif} alt="" className="adv-gif" />
                  <p className="get-all">
                    Scan each item of your items by just putting them into the
                    basket and proceed to pay
                  </p>
                </div>
                <div>
                  <img src={SignupGif} alt="" className="adv-gif" />
                  <p className="get-all">
                    Walk away with your favourite purchases, we are happy to see
                    you independent
                  </p>
                </div>
              </Carousel>
            </div>
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
					<Row className="inputs-row" justify="center" align="middle">
          <Col span={2}></Col>
						<Col span={20} style={{marginTop:"-10px"}}>
              <Row justify="center" className="info-div">
                <p className="seeking">
                  <img src={Announcement} alt="" /> &nbsp; Seeking your
                  Attention
                </p>
                <p className="for-any-help">
                  For any help and checkout problem or Payment strucked issues,
                  please reach out to our store person and they will help you
                  out
                </p>
              </Row>
            </Col>
            <Col span={2}></Col>
						<Col span={5}></Col>
						<Col span={14}>
            <Spin indicator={antIcon} spinning={loading.status} tip={loading.message}>
							<Row justify="center" style={{marginTop:"-75px"}}>
								<Title level={3}>Welcome to Decathlon!</Title>
                <Paragraph style={{color: "gray",marginTop:'-6px'}}>
                  Please Register the Terminal to your account and you can proceed with the
                  checkout
                </Paragraph>
                <Input ref={userName1} style={{ width: 400 }} value={userName} onChange={handleUserName} onFocus={() => { setKeyboard(1) }} onClick={() => { setKeyboard(1) }} placeholder="User Name" className="user-input" />
                <Input.Password style={{ width: 400, marginTop: "15px" }} value={password} onChange={handlePassword} onFocus={() => { setKeyboard(2) }} onClick={() => { setKeyboard(2) }} placeholder="Password" className="user-input" />
                <Input style={{ width: 400, marginTop: "15px", marginBottom: "15px" }} onPressEnter={handleHome} value={terminalNumber} onChange={handleTerminal} onFocus={() => { setKeyboard(3) }} onClick={() => { setKeyboard(3) }} placeholder="Terminal Number" className="user-input" />
                <Button style={{ width: 400, marginTop: "0px", marginBottom: "50px" }} onClick={handleHome} htmlType="submit" className="continue-btn">
                  Continue
                </Button>
							</Row>
              </Spin>
						</Col>
            <Col span={5}></Col>
            <Col span={22} style={{marginTop:"-30px"}}>
                <Keyboard
                  layoutName={layoutUserName}
                  onKeyPress={onKeyPress}
                  display={{
                    '{bksp}' : 'backspace',
                    '{tab}' : 'tab',
                    '{lock}' : 'lock',
                    '{enter}' : 'enter',
                    '{shift}' : 'shift',
                    '{space}' : ' ',
                  }}
                /> 
            </Col>
					</Row> 
        </Col>
      </Row>
    </div>
  );
};

export default Signup;
