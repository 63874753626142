import React, { useState, useEffect ,useRef} from "react";
import { json, useNavigate } from "react-router-dom";
import { Row, Col, Spin, Steps, Badge, Modal, Button,message,Input } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Logo from "../../assets/SCO_asset/home/decathlon-logo.png";
import CardSwipe from "../../assets/SCO_asset/payment/card-swipe.gif";
import TimerGif from "../../assets/SCO_asset/payment/timer.gif";
import Axios from "axios";
import moment from "moment";
import URL from "../../app/server.json";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

const { Step } = Steps;

const CardPayment = () => {
  const navigate = useNavigate();
  const [cardData,setCardData ] = useState("");
  const [time, setTime] = useState(0);
  const [msg, setMsg] = useState("");
  const [loginFlag, setLoginFlag] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [transactionFlag, setTransactionFlag] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [validateData, setValidateData] = useState();
  const [name, setName] = useState("");
  const [keyboard, setKeyboard] = useState(1);
  const [layoutUserName, setlayoutUserName] = useState("default");
  const [layoutPassword, setlayoutPassword] = useState("default");
  const [manualFlag,setManulaFlag] = useState(false);
  const [paymentCancel,setPaymentCancel] = useState(true);
  let token = localStorage.getItem("token");
  const cashierData = JSON.parse(localStorage.getItem("cashierData"));
  let basicAuth = `${cashierData?.Username}:${cashierData?.Password}`

  let index = 0;

  useEffect(() => {
    let tempName = localStorage.getItem("surname");
    setName(tempName);
  }, []);
  
  useEffect(() => {
    if (index === 0) {
      let total = 0;
      let discount = 0;
      let storePhone;
      let storeManagerMailId;
      let locationStore;
      let store;
      let customerData = JSON.parse(localStorage.getItem('customerData'));
      let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
      let orderId = localStorage.getItem("order_lastassignednum");
      let terminalId = localStorage.getItem("terminalNumber");
      selectedProducts = selectedProducts === null ? {} : selectedProducts;
      if (selectedProducts.productsData !== undefined || selectedProducts.productsData !== null) {
        selectedProducts.productsData.map((item, ind) => {
          total = total + item.sale_price;
          discount = discount+ (item.sale_price *item.percentage)/100;
        });
        setTime(selectedProducts.tillData[0].paymentConfigurations.phonepeConfigurations.verifyTimeout);
        storePhone = selectedProducts.tillData[0].storeDetail.storePhoneNo;
        storeManagerMailId = selectedProducts.tillData[0].storeDetail.storeManagerMailId;
        locationStore = selectedProducts.tillData[0].storeDetail.storeAddress.storeCityName;
        store = selectedProducts.tillData[0].storeDetail.storeName;
      };
      Axios({
        url: `${URL.MAIN_URL}/start`,
        method: "POST",
        data:{
          "amount": (total-discount).toFixed(2),
          "documentNo": `${terminalId}/${Number(orderId)+1}-${moment(new Date()).format("DD-MM-YYYY HH:mm:ss")}`,
          "mobNumber": `${customerData.phoneNo}`,
          "syncEmail": `${customerData.email}`,
          "storePhone": `${storePhone}`,
          "storeManagerMail": `${storeManagerMailId}`,
          "locationStore": `${locationStore}`,
          "store": `${store}`,
          "terminalName": `${terminalId}`,
        },
        headers: {
          Authorization: `Basic ${window.btoa(basicAuth)},${token}`,
          "Content-Type": "application/json"
        },
        params:{
          "amount": (total-discount).toFixed(2),
          "documentNo": `${terminalId}/${Number(orderId)+1}-${moment(new Date()).format("DD-MM-YYYY HH:mm:ss")}`,
          "mobNumber": `${customerData.phoneNo}`,
          "syncEmail": `${customerData.email}`,
          "storePhone": `${storePhone}`,
          "storeManagerMail": `${storeManagerMailId}`,
          "locationStore": `${locationStore}`,
          "store": `${store}`,
          "terminalName": `${terminalId}`,
        },
      }).then((response) => {
        if(response.status === 200) {
          setCardData(response.data);
          localStorage.setItem("transactionId", response.data?.p2pRequestId);
          if(response.data.status === 1){
            message.error("Card Payment Device is not Connected");
            navigate("/payment-selection");
          }
        } else {
          message.error("Card Payment Device is not Connected");
          navigate("/payment-selection");
        }
      })
    };
    index++;
  }, []);


  useEffect(() => {
    const cashierData = JSON.parse(localStorage.getItem("cashierData"));
    let basicAuth = `${cashierData.Username}:${cashierData.Password}`
    if(time !== 0){
      const timer = setInterval(() => {
        if (cardData?.status === 0) {
          let token = localStorage.getItem("token");
          let orderId = localStorage.getItem("order_lastassignednum");
          let terminalId = localStorage.getItem("terminalNumber");
          Axios({
            url: `${URL.MAIN_URL}/statusCheck`,
            method: "POST",
            data:{
              "documentNo": `${terminalId}/${Number(orderId)+1}`,
              "origP2pRequestId": `${cardData?.data?.p2pRequestId}`,
              },
            headers: {
              Authorization: `Basic ${window.btoa(basicAuth)},${token}`,
              "Content-Type": "application/json"
            },
            params:{
              "documentNo": `${terminalId}/${Number(orderId)+1}`,
              "origP2pRequestId": `${cardData?.data?.p2pRequestId}`,
            }
          }).then((response) => {
            if(response.status === 200) {
              setValidateData(response.data);
              setManulaFlag(true);
              setMsg(response.data.message);
              if (response.data.status === 0) {
                localStorage.setItem("response", JSON.stringify(response.data));
                localStorage.setItem("isCardPayment", "Y");
                localStorage.setItem("ismanual", "N");
                navigate("/bill-mode");
              } else if(response.data.status === 1) {
                console.log(response.data.data.status);
              } 
            } else {
              message.error("Something is wrong");
              navigate("/card-payment");
            }
          });
        }
      }, 1000);
      
      if (msg === "Transaction Complete!") {
        clearInterval(timer);
      }
      return () => {
        clearInterval(timer);
      };
    }
    });
  
    useEffect(() => {
      if(!paymentCancel){
        setTimeout(()=>{
          navigate("/payment-selection");
          },2000)
        }
      const timer = setInterval(() => {
        setTime(time - 1);
      }, 1000);
  
      if (time === 0) {
        clearInterval(timer);
      }
  
      return () => {
        clearInterval(timer);
      };
    });

    const cancelPayment =()=>{
      if(manualFlag){
        Axios({
          url: `${URL.MAIN_URL}/callCancel`,
          method: "POST",
          data:{
            "origP2pRequestId": `${cardData?.data?.p2pRequestId}`
          },
          headers: {
            Authorization: `Basic ${window.btoa(basicAuth)},${token}`,
            "Content-Type": "application/json"
          },
          params:{
            "origP2pRequestId": `${cardData?.data?.p2pRequestId}`
          },
        }).then((response) => {
          if(response.status === 200) {
            setPaymentCancel(false);
          } else {
            message.error("Something is wrong");
          }
        })
      }else{
        setPaymentCancel(false);
            setTimeout(()=>{
            navigate("/payment-selection");
            },2000)
      }
    }

    const handleUsername = (ev) => {
      setUsername(ev.target.value);
    };
  
    const handlePassword = (ev) => {
      setPassword(ev.target.value);
    };

    const handleTransactionId = (ev) => {
      setTransactionId(ev.target.value);
    };
  
    const handleVerify = () => {
      setTransactionFlag(false);
      localStorage.setItem("tempTransactionId", transactionId);
      localStorage.setItem("ismanual", "Y");
      localStorage.setItem("isCardPayment", "Y");
      localStorage.setItem("response", JSON.stringify(validateData));
      let ebill = localStorage.getItem("ebill");
      if (ebill === "true") {
        navigate("/bill-mode");
      } else {
        localStorage.setItem("billMode","Printed Receipt");
        navigate("/feedback-steptwo");
      };
    };

    const handleLogin = () => {
      setErrorMsg("");
      setLoading(true);
      let cashierData = JSON.parse(localStorage.getItem('cashierData'));
      if(username === cashierData.Username && password === cashierData.Password){
        setLoading(false);
            setLoginFlag(false);
            setTransactionFlag(true);
      }else{
        setLoading(false);
        setLoginFlag(false);
        setTransactionFlag(true);
        setErrorMsg("Please give valid credentials"); 
      }
     };

    const handlePayment = () => {
      navigate('/success-payment')
    }

    const handleShift = () => {
      const layout1 = layoutUserName;
      const layout2 = layoutPassword;
      setlayoutUserName(layout1 === "default" ? "shift" : "default");
      setlayoutPassword(layout2 === "default" ? "shift" : "default");
    };

    const onKeyPress = (button) => {
      if(keyboard === 1){
        if (button === "{bksp}") {
          setUsername(username.slice(0, -1));
        };
        if (button === "{shift}" || button === "{lock}") handleShift();
        if(button !== "{shift}" && button !== "{lock}" && button !== "{bksp}" & button !== "{tab}"&& button !== "{enter}" & button !== "{space}"){
          let text = `${username}${button}`
          setUsername(text.trim());
        }
      }else if (keyboard === 2){
        if (button === "{bksp}") {
          setPassword(password.slice(0, -1));
        };
        if (button === "{shift}" || button === "{lock}") handleShift();
        if(button !== "{shift}" && button !== "{lock}" && button !== "{bksp}" & button !== "{tab}"&& button !== "{enter}" & button !== "{space}"){
          let text = `${password}${button}`
          setPassword(text.trim());
        }
      }
    };


  return (
    <div>
    <div className="header-block">
      <Row>
      <Col span={1} >
        </Col>
        <Col span={6} style={{ alignSelf: "center",position:"relative",left:"-2%"}}>
          <p className="user-name">Hey {name}</p>
        </Col>
        <Col span={9} style={{position:"relative",left:"2.6%"}}>
        </Col>
        <Col span={8} className="user-details">
        <img src={Logo} alt="" width={100} />
        </Col>
      </Row>
      <Row style={{ marginTop: "1.5rem" }}>
      <Steps current={2} size="small">
          <Step
            title="Register"
            description={<Badge status="success" text="Completed" />}
          />
          <Step
            title="Scan"
            description={<Badge status="success" text="Completed" />}
          />
          <Step
            title="Payment"
            description={<Badge status="success" text="In Progress" />}
          />
          <Step title="Feedback" />
        </Steps>
      </Row>
    </div>
    <div className="info-block">
      <p className="info-text">Please don’t close or cancel this page, Your Payment can get affected</p>
    </div>
    <Row>
      <Col span={12} className="payment-gif-block">
      <img className="card-payment-img" src={CardSwipe} alt=""/>
      </Col>
      <Col span={12} className="payment-method-block">
        {
        paymentCancel ? 
        <>
        <img className="waiting-img" src={TimerGif} width="70px" height="70px" alt="" onClick={handlePayment}/>
      {time !== 0 ? 
              <>
                <p className="please-insert">
                Please insert the card in the machine to make the payment
                </p>
                <p className="remaining-time">{time}</p>
                <p className="seconds-left">Seconds left</p>
              </> :
              <p>
                Payment Incomplete
                Click BACK to choose different Payment or QUERY Icon to Manual Verify
              </p>}
        {time === 0 ?
          <div style={{paddingTop:"15%", display:cardData !== "" && manualFlag ? "": "flex" ,alignItems:cardData !== "" && manualFlag ? "" : "center",justifyContent:cardData !== "" && manualFlag ? "" : "center"}}>
            {manualFlag ?<Button style={{ cursor: "pointer", fontSize: "14px",backgroundColor: "#0083c6", borderRadius: "4px",  color: "white",marginLeft:"5%",float:"left"}} onClick={() => { setLoginFlag(true); }}>Manual Card</Button> : ""}
            <Button style={{ cursor: "pointer", fontSize: "14px",borderRadius: "4px",float:"right",marginRight:manualFlag ? "5%" : ""}} onClick={cancelPayment}>Cancel</Button>
          </div>
          :""
        }
        {
          time !== 0? 
        <p>NOTE:If you want to cancel the payment kindly press RED button on card device</p>
          : ""
        }
        </> : 
        <p style={{fontSize:"4vh"}}>
          Transaction cancelled
        </p>
        }
      </Col>
    </Row>
    <Modal centered open={loginFlag} closable={false} footer={null} width={500}>
      <Spin spinning={loading} indicator={<LoadingOutlined />}>
          Username
          <Input onChange={handleUsername} style={{ width: 400, marginTop: "15px" }} value={username}  onFocus={() => { setKeyboard(1) }} onClick={() => { setKeyboard(1) }} placeholder="User Name" className="user-input" />
          <br />
          <br />
          Password
          <Input.Password value={password} onChange={handlePassword} onPressEnter={handleLogin} style={{ width: 400, marginTop: "15px" }} onFocus={() => { setKeyboard(2) }} onClick={() => { setKeyboard(1) }} placeholder="Password" className="user-input" />
          <br />
          <br />
          <div style={{ textAlign: "center", marginBottom: "5px",  marginTop: "-15px", color: "red"}}>{errorMsg}</div>
          <div style={{ display: "flex", justifyContent: "space-between" ,marginBottom:"15px"}}>
            <Button style={{ borderRadius: "4px" }} onClick={() => { setLoginFlag(false); setErrorMsg(""); setUsername(""); setPassword(""); }}>Cancel</Button>
            <Button style={{ backgroundColor: "#0083c6", borderRadius: "4px",  color: "white" }} onClick={handleLogin}>Login</Button>
          </div>
            <Keyboard
              layoutName={layoutUserName}
                onKeyPress={onKeyPress}
                display={{
                  '{bksp}' : 'backspace',
                  '{tab}' : 'tab',
                  '{lock}' : 'lock',
                  '{enter}' : 'enter',
                  '{shift}' : 'shift',
                  '{space}' : ' ',
                }}
              />
      </Spin>
    </Modal>
    <Modal centered open={transactionFlag} closable={false} footer={null} width={500}>
          Transaction ID
          <Input value={transactionId} onChange={handleTransactionId} onPressEnter={handleVerify} style={{ width: "100%" }} onFocus={() => { setKeyboard(3) }} onClick={() => { setKeyboard(3) }}/>
          <br />
          <br />
          <br />
          <br />
          <div justify="center" align="middle" style={{marginBottom:"25px"}}>
            <Button className="continue-btn" onClick={handleVerify}>Verify</Button>
          </div>
          <Keyboard
              layoutName={layoutUserName}
                onKeyPress={onKeyPress}
                display={{
                  '{bksp}' : 'backspace',
                  '{tab}' : 'tab',
                  '{lock}' : 'lock',
                  '{enter}' : 'enter',
                  '{shift}' : 'shift',
                  '{space}' : ' ',
                }}
              />
    </Modal>
  </div>
  );
};

export default CardPayment;
