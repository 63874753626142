import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Steps, Badge, message, Modal, Input, Button, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import Logo from "../../assets/SCO_asset/home/decathlon-logo.png";
import QrGif from "../../assets/SCO_asset/payment/qrCode.gif";
import Axios from "axios";
import QRCode from "react-qr-code";
import "./style.css";
import URL from "../../app/server.json";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

const { Step } = Steps;

const QrPayment = () => {
  const navigate = useNavigate();
  const [qrData, setQrData] = useState();
  const [time, setTime] = useState("");
  const [msg, setMsg] = useState("");
  const [loginFlag, setLoginFlag] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [transactionFlag, setTransactionFlag] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [validateData, setValidateData] = useState();
  const [name, setName] = useState("");
  const [keyboard, setKeyboard] = useState(1);
  const [layoutUserName, setlayoutUserName] = useState("default");
  const [layoutPassword, setlayoutPassword] = useState("default");
  let index = 0;

  useEffect(() => {
    let tempName = localStorage.getItem("surname");
    setName(tempName);
  }, []);

  useEffect(() => {
    if (index === 0) {
      let total = 0;
      let discount = 0;
      let callBackApi;
      let checkStatusApi;
      let dynamicQrApi;
      let dynamicQrAuthIndex;
      let dynamicQrAuthkey;
      let expiresInTime;
      let phonePeMerchantId;
      let verifyLevel2;
      let cashierData = JSON.parse(localStorage.getItem('cashierData'));
      let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
      let orderId = localStorage.getItem("order_lastassignednum");
      let terminalId = localStorage.getItem("terminalNumber");
      selectedProducts = selectedProducts === null ? {} : selectedProducts;
      if (selectedProducts.productsData !== undefined || selectedProducts.productsData !== null) {
        selectedProducts.productsData.map((item, ind) => {
          total = total + item.sale_price;
          discount = discount+ (item.sale_price *item.percentage)/100;
        });
        setTime(selectedProducts.tillData[0].paymentConfigurations.phonepeConfigurations.verifyTimeout);
        callBackApi = selectedProducts.tillData[0].paymentConfigurations.phonepeConfigurations.callBackApi;
        checkStatusApi = selectedProducts.tillData[0].paymentConfigurations.phonepeConfigurations.checkStatusApi;
        dynamicQrApi = selectedProducts.tillData[0].paymentConfigurations.phonepeConfigurations.dynamicQrApi;
        dynamicQrAuthIndex = selectedProducts.tillData[0].paymentConfigurations.phonepeConfigurations.dynamicQrAuthIndex;
        dynamicQrAuthkey = selectedProducts.tillData[0].paymentConfigurations.phonepeConfigurations.dynamicQrAuthkey;
        expiresInTime = selectedProducts.tillData[0].paymentConfigurations.phonepeConfigurations.expiresInTime;
        phonePeMerchantId = selectedProducts.tillData[0].paymentConfigurations.phonepeConfigurations.phonePeMerchantId;
        verifyLevel2 = selectedProducts.tillData[0].paymentConfigurations.phonepeConfigurations.verifyLevel2;
      };
      Axios({
        url: `${URL.MAIN_URL}/qrCode`,
        method: "PUT",
        data:{
          "amount": (total-discount).toFixed(2),
          "callBackApi": `${callBackApi}`,
          "cashierName": `${cashierData.Username}`,
          "checkStatusApi": `${checkStatusApi}`,
          "dynamicQrApi": `${dynamicQrApi}`,
          "dynamicQrAuthIndex": dynamicQrAuthIndex,
          "dynamicQrAuthkey": `${dynamicQrAuthkey}`,
          "expiresInTime": `${expiresInTime}`,
          "message": "Plase complete the payment",
          "phonePeMerchantId": `${phonePeMerchantId}`,
          "storeId": `${'7' + terminalId.toString().substring(2, 6) + terminalId.toString().substring(2, 6)}`,
          "terminalId": `${terminalId}`,
          "transactionId": `${terminalId}/${orderId+1}`,
          "verifyLevel2": `${verifyLevel2}`
        },
        headers: {
          "Content-Type": "application/json"
        },
        params:{
          "amount": (total-discount).toFixed(2),
          "callBackApi": `${callBackApi}`,
          "cashierName": `${cashierData.Username}`,
          "checkStatusApi": `${checkStatusApi}`,
          "dynamicQrApi": `${dynamicQrApi}`,
          "dynamicQrAuthIndex": dynamicQrAuthIndex,
          "dynamicQrAuthkey": `${dynamicQrAuthkey}`,
          "expiresInTime": `${expiresInTime}`,
          "message": "Plase complete the payment",
          "phonePeMerchantId": `${phonePeMerchantId}`,
          "storeId": `${'7' + terminalId.toString().substring(2, 6) + terminalId.toString().substring(2, 6)}`,
          "terminalId": `${terminalId}`,
          "transactionId": `${terminalId}/${orderId+1}`,
          "verifyLevel2": `${verifyLevel2}`
        },
      }).then((response) => {
        if(response.status === 200) {
          setQrData(response.data);
          localStorage.setItem("transactionId", response.data.transactionId);
        } else {
          message.error("Something is wrong");
        }
      })
    };
    index++;
  }, []);

  useEffect(() => {
  if(time !== 0){
    const timer = setInterval(() => {
      if (qrData?.transactionId !== undefined) {
        let callBackApi;
        let checkStatusApi;
        let dynamicQrApi;
        let dynamicQrAuthIndex;
        let dynamicQrAuthkey;
        let expiresInTime;
        let phonePeMerchantId;
        let verifyLevel2;
        let statusCheckDuration;
        let verifyTimeout;
        let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
        selectedProducts = selectedProducts === null ? {} : selectedProducts;
        if (selectedProducts.productsData !== undefined || selectedProducts.productsData !== null) {
          callBackApi = selectedProducts.tillData[0].paymentConfigurations.phonepeConfigurations.callBackApi;
          checkStatusApi = selectedProducts.tillData[0].paymentConfigurations.phonepeConfigurations.checkStatusApi;
          dynamicQrApi = selectedProducts.tillData[0].paymentConfigurations.phonepeConfigurations.dynamicQrApi;
          dynamicQrAuthIndex = selectedProducts.tillData[0].paymentConfigurations.phonepeConfigurations.dynamicQrAuthIndex;
          dynamicQrAuthkey = selectedProducts.tillData[0].paymentConfigurations.phonepeConfigurations.dynamicQrAuthkey;
          expiresInTime = selectedProducts.tillData[0].paymentConfigurations.phonepeConfigurations.expiresInTime;
          phonePeMerchantId = selectedProducts.tillData[0].paymentConfigurations.phonepeConfigurations.phonePeMerchantId;
          verifyLevel2 = selectedProducts.tillData[0].paymentConfigurations.phonepeConfigurations.verifyLevel2;
          statusCheckDuration = selectedProducts.tillData[0].paymentConfigurations.phonepeConfigurations.statusCheckDuration;
          verifyTimeout = selectedProducts.tillData[0].paymentConfigurations.phonepeConfigurations.verifyTimeout;
        };
        Axios({
          url: `${URL.MAIN_URL}/validate`,
          method: "PUT",
          data:{
            "callBackApi": `${callBackApi}`,
            "checkStatusApi": `${checkStatusApi}`,
            "dynamicQrApi": `${dynamicQrApi}`,
            "dynamicQrAuthIndex": dynamicQrAuthIndex,
            "dynamicQrAuthkey": `${dynamicQrAuthkey}`,
            "expiresInTime": `${expiresInTime}`,
            "phonePeMerchantId": `${phonePeMerchantId}`,
            "statusCheckDuration": `${statusCheckDuration}`,
            "transactionId": `${qrData?.transactionId}`,
            "verifyLevel2": `${verifyLevel2}`,
            "verifyTimeout": `${verifyTimeout}`
            },
          headers: {
            "Content-Type": "application/json"
          },
          params:{
            "callBackApi": `${callBackApi}`,
            "checkStatusApi": `${checkStatusApi}`,
            "dynamicQrApi": `${dynamicQrApi}`,
            "dynamicQrAuthIndex": dynamicQrAuthIndex,
            "dynamicQrAuthkey": `${dynamicQrAuthkey}`,
            "expiresInTime": `${expiresInTime}`,
            "phonePeMerchantId": `${phonePeMerchantId}`,
            "statusCheckDuration": `${statusCheckDuration}`,
            "transactionId": `${qrData?.transactionId}`,
            "verifyLevel2": `${verifyLevel2}`,
            "verifyTimeout": `${verifyTimeout}`
          }
        }).then((response) => {
          if(response.status === 200) {
            setValidateData(response.data);
            setMsg(JSON.parse(response.data.message));
            if (JSON.parse(response.data.message) === "Your payment is successful.") {
              localStorage.setItem("response", JSON.stringify(response.data));
              localStorage.setItem("isphonepe", "Y");
              localStorage.setItem("ismanual", "N");
              navigate("/success-payment");
            } else if(JSON.parse(response.data.message) === "Payment Failed") {
              console.log(JSON.parse(response.data.message));
            } 
          } else {
            message.error("Something is wrong");
            navigate("/qr-payment");
          }
        });
      }
    }, 1000);
    
    if (msg === "Your payment is successful." || msg === "Payment Failed") {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(time - 1);
    }, 1000);

    if (time === 0) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  });

  const handleUsername = (ev) => {
    setUsername(ev.target.value);
  };

  const handlePassword = (ev) => {
    setPassword(ev.target.value);
  };

  const handleTransactionId = (ev) => {
    setTransactionId(ev.target.value);
  };

  const handleVerify = () => {
    setTransactionFlag(false);
    localStorage.setItem("tempTransactionId", transactionId);
    localStorage.setItem("ismanual", "Y");
    localStorage.setItem("isphonepe", "Y");
    localStorage.setItem("response", JSON.stringify(validateData));
    let ebill = localStorage.getItem("ebill");
    if (ebill === "true") {
      localStorage.setItem("processing",true);
      navigate("/bill-mode");
    } else {
      localStorage.setItem("billMode","Printed Receipt");
      localStorage.setItem("processing",true);

      navigate("/feedback-steptwo");
    };
  };

  const handleLogin = () => {
    setErrorMsg("");
    setLoading(true);
    let cashierData = JSON.parse(localStorage.getItem('cashierData'));
    if(username === cashierData.Username && password === cashierData.Password){
      setLoading(false);
          setLoginFlag(false);
          setTransactionFlag(true);
    }else{
      setErrorMsg("Please give valid credentials"); 
    }
   };

   const handleShift = () => {
    const layout1 = layoutUserName;
    const layout2 = layoutPassword;
    setlayoutUserName(layout1 === "default" ? "shift" : "default");
    setlayoutPassword(layout2 === "default" ? "shift" : "default");
  };

  const onKeyPress = (button) => {
    if(keyboard === 1){
      if (button === "{bksp}") {
        setUsername(username.slice(0, -1));
      };
      if (button === "{shift}" || button === "{lock}") handleShift();
      if(button !== "{shift}" && button !== "{lock}" && button !== "{bksp}" & button !== "{tab}"&& button !== "{enter}" & button !== "{space}"){
        let text = `${username}${button}`
        setUsername(text.trim());
      }
    }else if (keyboard === 2){
      if (button === "{bksp}") {
        setPassword(password.slice(0, -1));
      };
      if (button === "{shift}" || button === "{lock}") handleShift();
      if(button !== "{shift}" && button !== "{lock}" && button !== "{bksp}" & button !== "{tab}"&& button !== "{enter}" & button !== "{space}"){
        let text = `${password}${button}`
        setPassword(text.trim());
      }
    }
  };

  return (
    <div>
      <div className="header-block">
        <Row>
        <Col span={1} >
          </Col>
          <Col span={6} style={{ alignSelf: "center",position:"relative",left:"-2%"}}>
            <p className="user-name">Hey {name}</p>
          </Col>
          <Col span={9} style={{position:"relative",left:"2.6%"}}>
          </Col>
          <Col span={8} className="user-details">
          <img src={Logo} alt="" width={100} />
          </Col>
        </Row>
        <Row style={{ marginTop: "1.5rem" }}>
          <Steps current={2} size="small">
            <Step
              title="Register"
              description={<Badge status="success" text="Completed" />}
            />
            <Step
              title="Scan"
              description={<Badge status="success" text="Completed" />}
            />
            <Step
              title="Payment"
              description={<Badge status="success" text="In Progress" />}
            />
            <Step title="Feedback" />
          </Steps>
        </Row>
      </div>
      <div className="info-block">
        <p className="info-text">
          Please don’t close or cancel this page, Your Payment can get affected
        </p>
      </div>
      <Row>
        <Col span={12} className="payment-gif-block">
          <img
            className="card-payment-img"
            src={QrGif}
            alt=""
          />
        </Col>
        <Col span={12} className="payment-method-block">
          {qrData?.qrCode !== undefined ? 
            (time !== 0 ? 
              <>
                <p className="please-insert">
                  Please scan this QR code to make the payment
                </p>
                <QRCode value={qrData?.qrCode} style={{ height: 200, width: 200 }} />
                <p className="remaining-time">{time}</p>
                <p className="seconds-left">Seconds left</p>
              </> :
              <p>
                Payment Incomplete
                Click BACK to choose different Payment or QUERY Icon to Manual Verify
              </p>
            ) :
            ''
          }
          <p className="go-back" onClick={() => { navigate("/payment-selection") }} style={{ cursor: "pointer", fontSize: "16px" }} >Back</p>
          {time === 0 ?
            <p className="seconds-left" style={{ cursor: "pointer", fontSize: "25px" }} onClick={() => { setLoginFlag(true); }}>?</p> :
            ""
          }
        </Col>
      </Row>
      <Modal centered open={loginFlag} closable={false} footer={null} width={500}>
        <Spin spinning={loading} indicator={<LoadingOutlined />}>
            Username
            <Input onChange={handleUsername} style={{ width: 400, marginTop: "15px" }} value={username}  onFocus={() => { setKeyboard(1) }} onClick={() => { setKeyboard(1) }} placeholder="User Name" className="user-input" />
            <br />
            <br />
            Password
            <Input.Password value={password} onChange={handlePassword} onPressEnter={handleLogin} style={{ width: 400, marginTop: "15px" }} onFocus={() => { setKeyboard(2) }} onClick={() => { setKeyboard(1) }} placeholder="Password" className="user-input" />
            <br />
            <br />
            <div style={{ textAlign: "center", marginBottom: "5px",  marginTop: "-15px", color: "red"}}>{errorMsg}</div>
            <div style={{ display: "flex", justifyContent: "space-between" ,marginBottom:"15px"}}>
              <Button style={{ borderRadius: "4px" }} onClick={() => { setLoginFlag(false); setErrorMsg(""); setUsername(""); setPassword(""); }}>Cancel</Button>
              <Button style={{ backgroundColor: "#0083c6", borderRadius: "4px",  color: "white" }} onClick={handleLogin}>Login</Button>
            </div>
              <Keyboard
                layoutName={layoutUserName}
                  onKeyPress={onKeyPress}
                  display={{
                    '{bksp}' : 'backspace',
                    '{tab}' : 'tab',
                    '{lock}' : 'lock',
                    '{enter}' : 'enter',
                    '{shift}' : 'shift',
                    '{space}' : ' ',
                  }}
                />
        </Spin>
      </Modal>
      <Modal centered open={transactionFlag} closable={false} footer={null} width={500}>
            Transaction ID
            <Input value={transactionId} onChange={handleTransactionId} onPressEnter={handleVerify} style={{ width: "100%" }} onFocus={() => { setKeyboard(3) }} onClick={() => { setKeyboard(3) }}/>
            <br />
            <br />
            <br />
            <br />
            <div justify="center" align="middle" style={{marginBottom:"25px"}}>
              <Button className="continue-btn" onClick={handleVerify}>Verify</Button>
            </div>
            <Keyboard
                layoutName={layoutUserName}
                  onKeyPress={onKeyPress}
                  display={{
                    '{bksp}' : 'backspace',
                    '{tab}' : 'tab',
                    '{lock}' : 'lock',
                    '{enter}' : 'enter',
                    '{shift}' : 'shift',
                    '{space}' : ' ',
                  }}
                />
      </Modal>
    </div>
  );
};

export default QrPayment;
