import React,{useEffect, useRef,useState} from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Typography, Input, Button,Spin,Modal, Radio, message } from "antd";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "antd/dist/antd.css";
import { LoadingOutlined } from "@ant-design/icons";
import "../../antd.css";
import "../styles.css";
import LoginOne from "../../assets/SCO_asset/login-gif/login-1.gif";
import Logo1 from "../../assets/SCO_asset/home/decathlon-logo.png";
import LoginTwo from "../../assets/SCO_asset/login-gif/login-2.gif";
import LoginThree from "../../assets/SCO_asset/login-gif/login-3.gif";
import Announcement from "../../assets/SCO_asset/login-gif/announcement.svg";
import Axios from "axios";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import moment from "moment";
import URL from "../../app/server.json";

const { Title } = Typography;
const { Paragraph } = Typography;

const CustomerLogin = () => {
  const keyboard = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState({ status: false, message: "" });
  const [layoutName, setLayoutName] = useState("default");
  const [value, setValue] = useState(1);
  const [input, setInput] = useState("");
  const [routeFlag, setRouteFlag] = useState(false);


  const handleLogin = async() => {
    if (input !== "") {
     await getToken();
      setLoading({ status: true, message: "" });
      const token = localStorage.getItem("crm_token");
      let phno = value === 1 ? input : "";
      let email = value === 2 ? input : ""
      if(phno.length === 10 || email.includes("gmail")&&email.includes("@")&&email.includes(".com") || email.includes("yahoo")&&email.includes("@")&&email.includes(".com")|| email.includes("hotmail")&&email.includes("@")&&email.includes(".com")){
        Axios({
          url: `${URL.MAIN_URL}/customerLogin`,
          method: "POST",
          data:{
            "card_number": "",
            "email": `${email}`,
            "mobile": `${phno}`
            },
          headers: {
            "Content-Type": "application/json",
            "Connection": "keep-alive",
            'x-api-key':"609ed4ce-1869-4059-a6eb-3c5e40896b01",
            Authorization: `EMPLOYEE ${token}`,
          },
          params:{
            "card_number": "",
            "email": `${email}`,
            "mobile": `${phno}`
          }
        }).then(async(response)=>{
          if(response.status === 200){
            setLoading({ status: false, message: "" });
            let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
            selectedProducts = selectedProducts === null ? {} : selectedProducts;
            selectedProducts.productsData = [];
            localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
            let customerData = {phoneNo: phno,email:email}
            if (response.data.customers === null) {
              localStorage.setItem('customerData', JSON.stringify(customerData));
              navigate("/customer-signup");
            } else {
              let currentDate = moment(new Date()).format("YYYY-MM-DD");
              let currentDateTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
              selectedProducts.customerData = response.data;
              localStorage.setItem('surname', response.data.customers[0].surname);
              localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
              localStorage.setItem('customerData', JSON.stringify(customerData));
              localStorage.setItem('orderDate', currentDate);
              localStorage.setItem('salebegintime', currentDateTime);
              navigate("/home");
            }
          }else{
            setLoading({ status: false, message: "" });
          }
        });
      }else{
        if(value === 1){
          setLoading({ status: false, message: "" });
        message.error("Number should not be less than 10 digits");
        }else{
          setLoading({ status: false, message: "" });
          message.error("please enter valid email");
        }
        setLoading({ status: false, message: "" });
      }
    } else {
      message.warn("Please input your phone number or email");
      setLoading({ status: false, message: "" });
    };
  };

  const getToken = () => {
    setLoading({ status: true, message: "" });
    return new Promise(async (tokenDataSuccess, tokenDataFailure) => {
      try {
        const response = await Axios({
          url: `${URL.MAIN_URL}/token`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic Q2E3YmZmZWI4ZDY2NzQxZjI4OTM0OGFkNGQxOTcwOWRkYWY2NDA5YjY6U2t5ZVE0SEo5cmFHS3RJMWpNblg1Z1NxUmo0bnUwdG1LaVNzOEtIdVFzT1R5anF6VmhtblNacVJGbHZvRmhycA==",
          },
          params:{
            grant_type:"client_credentials"
          }
        });
        const tokenData  = [response.data];
        if(response.status === 200 && tokenData.length > 0){
          localStorage.setItem("isMasterRefresh",false);
          let token = localStorage.setItem("crm_token", response.data.access_token);
          tokenDataSuccess(token);
            localStorage.setItem("endTime", moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
        }else{
          setLoading({ status: false, message: "" });
          tokenDataSuccess();
        }
      } catch (error) {
        setLoading({ status: false, message: "" });
        console.log("SCO Token Data: ", " Sync Failed");
        tokenDataFailure(error);
      }
    });
  };

  const onChange = (inp) => {
    setInput(inp);
    keyboard.current.setInput(inp);
  };

  const onChangeNumber = (inp) => {
    if (inp.length <= 10) {
      setInput(inp);
      keyboard.current.setInput(inp);
    }; 
  };

  const onKeyPressNumber = (button) => {
    if (button === "{bksp}") {
      setInput(input.slice(0, -1));
      keyboard.current.setInput(input.slice(0, -1));
    };
  };

  const onKeyPress = (button) => {
    if (button === "{bksp}") {
      setInput(input.slice(0, -1));
      keyboard.current.setInput(input.slice(0, -1));
    };
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const handleShift = () => {
    const layout = layoutName;
    setLayoutName(layout === "default" ? "shift" : "default");
  };

  const onChangeRadio = (e) => {
    setValue(e.target.value);
    console.log(e.target.value)
    setInput("");
  };

  const handleInputChange = event => {
    console.log(value)
    const inp = event.target.value;
    if (value === 1) {
      if (event.target.value.length <= 10 && /^[6789]/.test(event.target.value)) {
        setInput(inp.replace(/\D/g, ""));
      }else if(!/^[6789]/.test(event.target.value)&&event.target.value.length === 0){
        setInput("");
      }else{
        message.error("Mobile Number Should Start with 6/7/8/9")
      }
    } else if(value === 2){
      setInput(inp);
      keyboard.current.setInput(inp);
    }
  };

  const antIcon = <LoadingOutlined style={{ fontSize: "24px" }} spin />;

  return (
    <div>
        <Spin indicator={antIcon} spinning={loading.status} tip={loading.message}>
        <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
        >
          <div className="adv-block">
            <img src={Logo1} alt="" style={{position:"relative",left:"-38%",top:"2%"}} onDoubleClick={()=>{ setRouteFlag(true); }}/>
            <div className="adv-block-div">
              <p className="shopping-with">
                Shopping with us has become more easier
              </p>
              <Carousel
                autoPlay={true}
                infiniteLoop={true}
                showArrows={false}
                showStatus={false}
                showIndicators={true}
                showThumbs={false}
              >
                <div>
                  <img src={LoginOne} alt="" className="adv-gif" />
                  <p className="get-all">
                    Get all the things you want in your cart and visit to the
                    self check out near the counter
                  </p>
                </div>
                <div>
                  <img src={LoginTwo} alt="" className="adv-gif" />
                  <p className="get-all">
                    Scan each item of your items by just putting them into the
                    basket and proceed to pay
                  </p>
                </div>
                <div>
                  <img src={LoginThree} alt="" className="adv-gif" />
                  <p className="get-all">
                    Walk away with your favourite purchases, we are happy to see
                    you independent
                  </p>
                </div>
              </Carousel>
            </div>
          </div>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
        >
          <Row className="inputs-row" justify="center" align="middle">
            <Col span={5}></Col>
            <Col span={14}>
              <Row justify="center">
                <Title level={3} style={{ color: "#333333" }}>
                  Hello Again!!
                </Title>
                <Paragraph style={{ color: "rgba(0, 0, 0, 0.4)" }}>
                  Please login to your account and you can proceed with the
                  checkout
                </Paragraph>
                <Radio.Group onChange={onChangeRadio} value={value}>
                  <Radio value={1}>Phone Number</Radio>
                  <Radio value={2}>Email</Radio>
                </Radio.Group>
                <br />
                <br />
                <Input style={{ width: value === 2 ? 400 : 200 }} autoFocus={true} onBlur={({ target }) => target.focus()} onPressEnter={handleLogin} value={input} onChange={handleInputChange} placeholder="Enter here" className="user-input" />
                <Button style={{ width: value === 2 ? 400 : 200, marginTop: "15px", marginBottom: "30px" }} onClick={handleLogin} htmlType="submit" className="continue-btn">
                  Continue
                </Button>
              </Row>
            </Col>
            <Col span={5}></Col>
            <Col span={2}></Col>
            <Col span={20}>
              <Row justify="center" className="info-div">
                <p className="seeking">
                  <img src={Announcement} alt="" /> &nbsp; Seeking your
                  Attention
                </p>
                <p className="for-any-help">
                  For any help and checkout problem or Payment strucked issues,
                  please reach out to our store person and they will help you
                  out
                </p>
              </Row>
            </Col>
            <Col span={2}></Col>
            {value === 1 ?
              <Col span={12}>
                <Keyboard
                  keyboardRef={r => (keyboard.current = r)}
                  onChange={onChangeNumber}
                  onKeyPress={onKeyPressNumber}
                  layout={{ 
                    default: [
                      "1 2 3",
                      "4 5 6",
                      "7 8 9",
                      " 0 {bksp}",
                    ]
                  }}
                  display={{
                    '{bksp}' : 'x'
                  }}
                />
              </Col> :
              <Col span={22}>
                <Keyboard
                  keyboardRef={r => (keyboard.current = r)}
                  layoutName={layoutName}
                  onChange={onChange}
                  onKeyPress={onKeyPress}
                  display={{
                    '{bksp}' : 'backspace',
                    '{tab}' : 'tab',
                    '{lock}' : 'lock',
                    '{enter}' : 'enter',
                    '{shift}' : 'shift',
                    '{space}' : ' ',
                  }}
                  layout={{ 
                    default: [
                      "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                      "{tab} q w e r t y u i o p [ ] \\",
                      "{lock} a s d f g h j k l ; ' {enter}",
                      "{shift} z x c v b n m , . / .com",
                      "@ {space} @gmail.com @yahoo.com @hotmail.com",
                    ],
                    shift: [
                      "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
                      "{tab} Q W E R T Y U I O P { } |",
                      '{lock} A S D F G H J K L : " {enter}',
                      "{shift} Z X C V B N M < > ? .com",
                      "@ {space} @gmail.com @yahoo.com @hotmail.com",
                    ]
                  }}
                />
              </Col>
            }
          </Row>
        </Col>
      </Row>
        </Spin>
      <Modal centered open={routeFlag} closable={false} footer={null} width={300}>
        <p style={{ fontSize: "15px", textAlign: "center" }}>Are you sure you want to cancel the transaction ?</p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button style={{ borderRadius: "4px" }} onClick={() => { setRouteFlag(false); }}>No</Button>
              <Button style={{ backgroundColor: "#0083c6", borderRadius: "4px",  color: "white" }} onClick={() => { navigate("/admin-login") }}>Yes</Button>
            </div>
      </Modal>
    </div>
  );
};

export default CustomerLogin;
