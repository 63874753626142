import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Steps, Badge, Card,message,Modal, Button } from "antd";
import Logo from "../../assets/SCO_asset/home/decathlon-logo.png";
import LeftArrow from "../../assets/SCO_asset/icons/leftArrow.svg";
import Tick from "../../assets/SCO_asset/ebill-buttons/tick.svg";
import OfferIcon from "../../assets/SCO_asset/products/offer-icon.svg";
import CardPayment from "../../assets/SCO_asset/payment/card.gif";
import UpiPayment from "../../assets/SCO_asset/payment/upi.gif";
import { Scrollbars } from "react-custom-scrollbars";
import _ from "lodash";
import moment from "moment";
import Axios from "axios";

const { Step } = Steps;

const PaymentSelection = () => {
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedItems, setSelectedItems] = useState(() => {
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    selectedProducts = selectedProducts === null ? {} : selectedProducts;
    if (selectedProducts.productsData !== undefined || selectedProducts.productData !== null) {
      return selectedProducts.productsData;
    } else {
      return [];
    }
  });
  const [summaryObj, setSummaryObj] = useState({ items : 0, count : 0, discount : 0, total : 0 });
  const [name, setName] = useState("");


  let token = localStorage.getItem("token");
  const cashierData = JSON.parse(localStorage.getItem("cashierData"));
  let basicAuth = `${cashierData?.Username}:${cashierData?.Password}`;

  useEffect(() => {
    let tempName = localStorage.getItem("surname");
    setName(tempName);
  }, []);

  useEffect(() => {
    let items = 0;
    let count = 0;
    let discount = 0;
    let total = 0;
    let arr = selectedItems?.length > 0 ? [...selectedItems] : [];
    arr.map((item, ind) => {
      items = items + ind + 1;
      count = count + item.count;
      discount = discount+ (item.sale_price *item.percentage)/100;
      total = total + (item.sale_price);
    });
    setSummaryObj({ items : items, count : count, discount : discount, total : total });
  }, [selectedItems]);


  const paymentSelectionList = [
    {
      title: "Pay with Card",
      img: CardPayment,
      description:
        "This will enable the payment machine and you can insert your card to proceed",
    },
    {
      title: "Pay with UPI",
      img: UpiPayment,
      description: "This will enable the QR code and you can scan to pay",
    },
  ];

  const handlePaymentSelection = (value, cardIndex) => {
    const selectedBillModeIndex = _.findIndex(paymentSelectionList, [
      "title",
      value,
    ]);
    if (selectedBillModeIndex === cardIndex) {
      setTimeout(()=>{
        if(value === "Pay with Card") {
          localStorage.setItem("processing",true);
          navigate('/card-payment');
          localStorage.setItem("paymentMode", "card");
        } else if(value === "Pay with UPI") {
          localStorage.setItem("processing",true);
          navigate('/qr-payment');
          localStorage.setItem("paymentMode", "PhonePe");
        }
      },1000)
      setSelectedValue(value);
    }
  };

  return (
    <div>
      <div className="header-block">
        <Row>
        <Col span={1} >
          </Col>
          <Col span={6} style={{ alignSelf: "center",position:"relative",left:"-2%"}}>
            <p className="user-name">Hey {name}</p>
          </Col>
          <Col span={9} style={{position:"relative",left:"2.6%"}}>
          </Col>
          <Col span={8} className="user-details">
          <img src={Logo} alt="" width={100} />
          </Col>
        </Row>
        <Row style={{ marginTop: "1.5rem" }}>
          <Steps current={2} size="small">
            <Step
              title="Register"
              description={<Badge status="success" text="Completed" />}
            />
            <Step
              title="Scan"
              description={<Badge status="success" text="Completed" />}
            />
            <Step
              title="Payment"
              description={<Badge status="success" text="In Progress" />}
            />
            <Step title="Feedback" />
          </Steps>
        </Row>
      </div>
      <div className="info-block">
        <p className="info-text">
          The next page is payment page and you will not be able to come back,
          so please cross check and proceed
        </p>
      </div>
      <Row>
        <Col span={17} className="payment-select-block">
          <p className="suggested" onClick={() => { navigate("/home"); }} style={{ cursor: "pointer" }}>
            {" "}
            <img src={LeftArrow} alt=""/> Back
          </p>
          <p className="please-insert">
            Please select a Payment option to proceed!
          </p>
          <Row style={{ margin: "auto" }}>
            <Col span={window.innerWidth <= 1024 && window.innerHeight <= 768 ? 4 : 6} />
            {paymentSelectionList.map((item, index) => (
              <Col
                span={window.innerWidth <= 1024 && window.innerHeight <= 768 ? 8 : 6}
                style={{ paddingRight: 20, cursor: "pointer" }}
                onClick={() => handlePaymentSelection(item.title, index)}
              >
                <img
                  src={Tick}
                  alt=""
                  className="tick-icon-payment"
                  style={{
                    visibility:
                      selectedValue === item.title ? "visible" : "hidden",
                      bottom:window.innerWidth <= 1024 && window.innerHeight <= 768 ? 310 : 265
                  }}
                />
                <Card
                  bodyStyle={{ padding: 0 }}
                  className={
                    selectedValue === item.title
                      ? "payment-selection-active-card"
                      : "payment-selection-card"
                  }
                >
                  <p className="payment-mode-text">{item.title}</p>
                  <img src={item.img} alt="" className="payment-selection-card-gif"/>
                  <p className="payment-select-desc">{item.description}</p>
                </Card>
              </Col>
            ))}
            <Col span={window.innerWidth <= 1024 && window.innerHeight <= 768 ? 4 : 6} />
          </Row>
        </Col>
        <Col span={7} className="current-order-block">
          <p className="current-order">Current Order</p>
          <p className="items-text">Items: {summaryObj.count}</p>
          <Scrollbars autoHide style={{ height: '40vh' }}>
          {selectedItems.map(item => {
              return (
                <Row style={{ marginBottom: 10 }}>
                  <Col span={window.innerWidth <= 1024 && window.innerHeight <= 768 ? 8 :6} className="img-col">
                    <img src={item.imageUrl} width={80} height={80} alt=""/>
                  </Col>
                  <Col span={10} style={{ paddingLeft: 20, alignSelf: "center" }}>
                    <p className="prod-name">{item.name}</p>
                    <p className="prod-qty">QTY:{item.count}</p>
                  </Col>
                  <Col span={6} style={{ alignSelf: "center" }}>
                    <p className="prod-price">₹ {item.sale_price}</p>
                    <p className="prod-discount">
                      <img src={OfferIcon} alt="" /> Discount: {item.percentage} % off
                    </p>
                  </Col>
                  <Col
                    span={window.innerWidth <= 1024 && window.innerHeight <= 768 ? 0 :2}
                    style={{
                      paddingLeft: 15,
                      paddingTop: "0.25rem",
                      textAlign: "right",
                    }}
                  >
                  </Col>
                </Row>
              )
            })}
          </Scrollbars>
          <div className="total-details-div">
            <Row>
              <Col span={12}>
                <p className="subtotal">Subtotal:</p>
              </Col>
              <Col span={12}>
                <p className="subtotal-price">₹ {summaryObj.total.toFixed(2)}</p>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <p className="discount-label">Discount:</p>
              </Col>
              <Col span={12}>
                <p className="discount-price">₹ {summaryObj.discount.toFixed(2)}</p>
              </Col>
            </Row>
            <hr style={{ border: "0.5px dashed rgba(0, 0, 0, 0.1)" }} />
            <Row>
              <Col span={12}>
                <p className="total">Total:</p>
              </Col>
              <Col span={12}>
                <p className="total-price">₹ {(summaryObj.total-summaryObj.discount).toFixed(2)}</p>
              </Col>
            </Row>
          </div>
          {/* <Button htmlType="submit" className="continue-btn-cart" disabled={selectedValue !== "" ? false : true} onClick={handlePayment}>
            Continue
          </Button> */}
        </Col>
      </Row>
    </div>
  );
};

export default PaymentSelection;
