import React,{useEffect,useState,useRef} from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Typography, Input, Button,Spin,Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "antd/dist/antd.css";
import "../../antd.css";
import "../styles.css";
import LoginOne from "../../assets/SCO_asset/login-gif/login-1.gif";
import LoginTwo from "../../assets/SCO_asset/login-gif/login-2.gif";
import LoginThree from "../../assets/SCO_asset/login-gif/login-3.gif";
import Announcement from "../../assets/SCO_asset/login-gif/announcement.svg";
import Axios from "axios";
import URL from "../../app/server.json";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

const { Title } = Typography;
const { Paragraph } = Typography;

const Login = () => {
  let keyboardRef = useRef([]);
  keyboardRef = {current: ["",""]}
  const navigate = useNavigate();
  const [loading, setLoading] = useState({ status: false, message: "" });
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [keyboard, setKeyboard] = useState(1);
  const [layoutUserName, setlayoutUserName] = useState("default");
  const [layoutPassword, setlayoutPassword] = useState("default");

  useEffect(() => {
    (async () => {
      const sessionId = localStorage.getItem("sessionId");
      if (sessionId !== "" &&  typeof(sessionId) === "string") {
        
      } else {
        navigate("/signup");
      }
    })();
  }, []);

  const handleLogin = async() => {
    setLoading({ status: true, message: "" });
    let sessionId = localStorage.getItem("sessionId");
    let terminalNumber = localStorage.getItem("terminalNumber");
    const token = localStorage.getItem("token");
    let basicAuth = `${userName}:${password}`;
    Axios({
      url: `${URL.MAIN_URL}/login`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Connection": "keep-alive",
        Authorization: `Basic ${window.btoa(basicAuth)},${token}`,
      },
      params: {
        remainingUrl : `/ws/com.promantia.decathlon.custom_selfcheckout.logincheck?l=${userName}&p=${password}&terminalname=${terminalNumber}&sessionId=${sessionId}`
      }
    }).then(async(response)=>{
      if(response.data.status === "SUCCESS"){
        setLoading({ status: false, message: "" });
         navigate("/customer-login");
      }else{
        setLoading({ status: false, message: "" });
        errorMessage("SCO Login Failed", "Unexpected Error");
        return null;
      }
    }).catch((err)=>{
      setLoading({ status: false, message: "" });
      errorMessage("SCO Login Failed", "Unexpected Error");
    });
  };

  const errorMessage = (modalTitle, modalContent) => {
    Modal.error({
      title: modalTitle,
      content: modalContent,
    });
  };

  const onKeyPress = (button) => {
    if(keyboard === 1){
      if (button === "{bksp}") {
        setUserName(userName.slice(0, -1));
        keyboardRef.current[0].setInput(userName.slice(0, -1));
      };
      if (button === "{shift}" || button === "{lock}") handleShift();
      if(button !== "{shift}" && button !== "{lock}" && button !== "{bksp}" & button !== "{tab}"&& button !== "{enter}" & button !== "{space}"){
        let text = `${userName}${button}`
        setUserName(text.trim());
      }
    }else if(keyboard === 2){
      if (button === "{bksp}") {
        setPassword(password.slice(0, -1));
        keyboardRef.current[1].setInput(password.slice(0, -1));
      };
      if (button === "{shift}" || button === "{lock}") handleShift();
      if(button !== "{shift}" && button !== "{lock}" && button !== "{bksp}" & button !== "{tab}"&& button !== "{enter}" & button !== "{space}"){
        let text = `${password}${button}`
        setPassword(text.trim());
      }
    }

  };

  const handleShift = () => {
    const layout1 = layoutUserName;
    const layout2 = layoutPassword;
    setlayoutUserName(layout1 === "default" ? "shift" : "default");
    setlayoutPassword(layout2 === "default" ? "shift" : "default");
  };

  const handleUserName = (ev) => {
    setUserName(ev.target.value);
  };

  const handlePassword = (ev) => {
    setPassword(ev.target.value);
  };

  const antIcon = <LoadingOutlined style={{ fontSize: "24px" }} spin />;

  return (
    <div>
      <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
        >
          <div className="adv-block">
            <div className="adv-block-div">
              <p className="shopping-with">
                Shopping with us has become more easier
              </p>
              <Carousel
                autoPlay={true}
                infiniteLoop={true}
                showArrows={false}
                showStatus={false}
                showIndicators={true}
                showThumbs={false}
              >
                <div>
                  <img src={LoginOne} alt="" className="adv-gif" />
                  <p className="get-all">
                    Get all the things you want in your cart and visit to the
                    self check out near the counter
                  </p>
                </div>
                <div>
                  <img src={LoginTwo} alt="" className="adv-gif" />
                  <p className="get-all">
                    Scan each item of your items by just putting them into the
                    basket and proceed to pay
                  </p>
                </div>
                <div>
                  <img src={LoginThree} alt="" className="adv-gif" />
                  <p className="get-all">
                    Walk away with your favourite purchases, we are happy to see
                    you independent
                  </p>
                </div>
              </Carousel>
            </div>
          </div>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
        >
          <Row className="inputs-row" justify="center">
            <Col span={2}></Col>
            <Col span={20}>
              <Row justify="center" className="info-div" style={{ marginBottom : "20px" }}>
                <p className="seeking">
                  <img src={Announcement} alt="" /> &nbsp; Seeking your
                  Attention
                </p>
                <p className="for-any-help">
                  For any help and checkout problem or Payment strucked issues,
                  please reach out to our store person and they will help you
                  out
                </p>
              </Row>
            </Col>
            <Col span={2}></Col>
            <Col span={5}></Col>
            <Col span={14}>
            <Spin indicator={antIcon} spinning={loading.status} tip={loading.message}>
              <Row justify="center">
                <Title level={3} style={{ color: "#333333" }}>
                  Login
                </Title>
                <Paragraph style={{ color: "rgba(0, 0, 0, 0.4)" }}>
                  Please login to your account and you can proceed with the
                  checkout
                </Paragraph>
                <Input style={{ width: 400, marginTop: "15px" }} value={userName} onChange={handleUserName} onFocus={() => { setKeyboard(1) }} onClick={() => { setKeyboard(1) }} placeholder="User Name" className="user-input" />
                <Input.Password  style={{ width: 400, marginTop: "15px", marginBottom: "15px" }} onPressEnter={handleLogin} value={password} onChange={handlePassword} onFocus={() => { setKeyboard(2) }} onClick={() => { setKeyboard(2) }} placeholder="Password" className="user-input" />
                <Button style={{ width: 400, marginTop: "0px", marginBottom: "50px" }} onClick={handleLogin} htmlType="submit" className="continue-btn">
                  Continue
                </Button>
              </Row>
            </Spin>
            </Col>
            <Col span={5}></Col>
            <Col span={22}>
                <Keyboard
                  layoutName={layoutUserName}
                  onKeyPress={onKeyPress}
                  display={{
                    '{bksp}' : 'backspace',
                    '{tab}' : 'tab',
                    '{lock}' : 'lock',
                    '{enter}' : 'enter',
                    '{shift}' : 'shift',
                    '{space}' : ' ',
                  }}
                /> 
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
