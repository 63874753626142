import React,{useState} from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Typography, Input, Button,Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "antd/dist/antd.css";
import "../../antd.css";
import "../styles.css";
import AdminLogin from "../../assets/SCO_asset/admiPageGif/AdminLogin.gif";
import Announcement from "../../assets/SCO_asset/login-gif/announcement.svg";
import db from "../../database";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

const { Title } = Typography;
const { Paragraph } = Typography;

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState({ status: false, message: "" });
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [keyboard, setKeyboard] = useState(1);
  const [layoutUserName, setlayoutUserName] = useState("default");
  const [layoutPassword, setlayoutPassword] = useState("default");

  const handleLogin = async() => {
    if (userName !== "" && password !== "") {
      setLoading({ status: true, message: "" });
      let cashData = await db.cashierData.toArray();
      if (cashData[0].Username === userName && cashData[0].Password === password) {
        setLoading({ status :false });
        navigate("/admin");
      } else {
        setLoading({ status :false });
        message.error("Please enter valid credentials");
      }
    } else {
      setLoading({ status :false });
      message.warn("Username and password are mandotory");
    };
  }

  const onKeyPress = (button) => {
    if (button === "{bksp}") {
      setPassword(password.slice(0, -1));
    };
    if (button === "{shift}" || button === "{lock}") handleShift();
    if(button !== "{shift}" && button !== "{lock}" && button !== "{bksp}" & button !== "{tab}"&& button !== "{enter}" & button !== "{space}"){
      let text = `${password}${button}`
      setPassword(text.trim());
    }
  };

  const handleShift = () => {
    const layout1 = layoutUserName;
    const layout2 = layoutPassword;
    setlayoutUserName(layout1 === "default" ? "shift" : "default");
    setlayoutPassword(layout2 === "default" ? "shift" : "default");
  };

  const handleUserName = (ev) => {
    setUserName(ev.target.value);
  };

  const handlePassword = (ev) => {
    setPassword(ev.target.value);
  };

  const antIcon = <LoadingOutlined style={{ fontSize: "24px" }} spin />;

  return (
    <div>
      <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
        >
          <div className="adv-block">
            <div className="adv-block-div">
              <p className="shopping-with">
              Manage the store and customers in a better way 
              </p>
                <div>
                  <img src={AdminLogin} alt="" className="adv-gif" />
                  <p className="get-all">
                  Get all the things you want in your cart and visit to the slef check out near the counter
                  </p>
                </div>
            </div>
          </div>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
        >
          <Row className="inputs-row" justify="center">
            <Col span={2}></Col>
            <Col span={20}>
            <Row justify="center" className="info-div" style={{ marginBottom : "10px" }}>
                <p className="seeking">
                  <img src={Announcement} alt="" /> &nbsp; Seeking your
                  Attention
                </p>
                <p className="for-any-help">
                  For any help and checkout problem or Payment strucked issues,
                  please reach out to our store person and they will help you
                  out
                </p>
              </Row>
            </Col>
            <Col span={2}></Col>
            <Col span={5}></Col>
            <Col span={14}>
            <Spin indicator={antIcon} spinning={loading.status} tip={loading.message}>
              <Row justify="center">
                <Title level={3} style={{ color: "#333333" }}>
                Hello Admin!
                </Title>
                <Paragraph style={{ color: "rgba(0, 0, 0, 0.4)" }}>
                Please login to your account and you can proceed with the store management
                </Paragraph>
                <div style={{ textAlign: "left", width:"100%", marginBottom: "-12px" }}>User Name</div>
                <Input style={{ width: 400, marginTop: "15px" }} value={userName} onChange={handleUserName} placeholder="User Name" className="user-input"  onFocus={() => { setKeyboard(1) }} onClick={() => { setKeyboard(1) }} />
                <div style={{ textAlign: "left", width:"100%", marginTop: "10px", marginBottom: "-12px" }}>Password</div>
                <Input.Password style={{ width: 400, marginTop: "15px", marginBottom: "15px" }} value={password} onChange={handlePassword} onPressEnter={handleLogin} placeholder="Password" className="user-input" onFocus={() => { setKeyboard(2) }} onClick={() => { setKeyboard(2) }}/>
                <Button style={{ width: 400, marginTop: "0px", marginBottom: "50px" }} onClick={handleLogin} htmlType="submit" className="continue-btn">
                  Continue
                </Button>
              </Row>
            </Spin>
            </Col>
            <Col span={5}></Col>
            <Col span={2}></Col>
            <Col span={20}>
                <Keyboard
                  value
                  layoutName={layoutPassword}
                  onKeyPress={onKeyPress}
                  display={{
                    '{bksp}' : 'backspace',
                    '{tab}' : 'tab',
                    '{lock}' : 'lock',
                    '{enter}' : 'enter',
                    '{shift}' : 'shift',
                    '{space}' : ' ',
                  }}
                />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
