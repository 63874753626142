import Dexie from "dexie";

const db = new Dexie("sco");
db.version(1)
  .stores({
    productData: "++id, ean",
    comboData:"OfferId",
    multiBuyData:"OfferId",
    tillData:"++id",
    cottonData:"++id",
    paperData:"++id",
    nonRfidData:"++id",
    incrementalData:"++id",
    cashierData:"++id",
    selectedData:"++id",
    syncData:"++id"
  })
  .upgrade((tx) => {
    // Database Migrations
    console.log("DB Migration -> ", tx);
  });

export default db;